const cityCodeList = [
  {
    "code": "110000",
    "region": "北京市",
    "regionEntitys": [
      {
        "code": "110000",
        "region": "北京市"       
      }
    ]
  },
  {
    "code": "120000",
    "region": "天津市",
    "regionEntitys": [
      {
        "code": "120000",
        "region": "天津市"       
      }
    ]
  },
  {
    "code": "130000",
    "region": "河北省",
    "regionEntitys": [
      {
        "code": "130100",
        "region": "石家庄市"
      },
      {
        "code": "130200",
        "region": "唐山市"
      },
      {
        "code": "130300",
        "region": "秦皇岛市"
      },
      {
        "code": "130400",
        "region": "邯郸市"
      },
      {
        "code": "130500",
        "region": "邢台市"
      },
      {
        "code": "130600",
        "region": "保定市"
      },
      {
        "code": "130700",
        "region": "张家口市"
        
      },
      {
        "code": "130800",
        "region": "承德市"
      },
      {
        "code": "130900",
        "region": "沧州市"
      },
      {
        "code": "131000",
        "region": "廊坊市"
      },
      {
        "code": "131100",
        "region": "衡水市"
      },
      {
        "code": "139000",
        "region": "省直辖县级行政区划"
      }
    ]
  },
  {
    "code": "140000",
    "region": "山西省",
    "regionEntitys": [
      {
        "code": "140100",
        "region": "太原市"
      },
      {
        "code": "140200",
        "region": "大同市"
      },
      {
        "code": "140300",
        "region": "阳泉市"
      },
      {
        "code": "140400",
        "region": "长治市"
      },
      {
        "code": "140500",
        "region": "晋城市"
      },
      {
        "code": "140600",
        "region": "朔州市"
      },
      {
        "code": "140700",
        "region": "晋中市"
      },
      {
        "code": "140800",
        "region": "运城市"
      },
      {
        "code": "140900",
        "region": "忻州市"
      },
      {
        "code": "141000",
        "region": "临汾市"
      },
      {
        "code": "141100",
        "region": "吕梁市"
      }
    ]
  },
  {
    "code": "150000",
    "region": "内蒙古自治区",
    "regionEntitys": [
      {
        "code": "150100",
        "region": "呼和浩特市"
      },
      {
        "code": "150200",
        "region": "包头市"
      },
      {
        "code": "150300",
        "region": "乌海市"
      },
      {
        "code": "150400",
        "region": "赤峰市"
      },
      {
        "code": "150500",
        "region": "通辽市"
      },
      {
        "code": "150600",
        "region": "鄂尔多斯市"
      },
      {
        "code": "150700",
        "region": "呼伦贝尔市"
      },
      {
        "code": "150800",
        "region": "巴彦淖尔市"
      },
      {
        "code": "150900",
        "region": "乌兰察布市"
      },
      {
        "code": "152200",
        "region": "兴安盟"
      },
      {
        "code": "152500",
        "region": "锡林郭勒盟"
      },
      {
        "code": "152900",
        "region": "阿拉善盟"
      }
    ]
  },
  {
    "code": "210000",
    "region": "辽宁省",
    "regionEntitys": [
      {
        "code": "210100",
        "region": "沈阳市"
      },
      {
        "code": "210200",
        "region": "大连市"
      },
      {
        "code": "210300",
        "region": "鞍山市"
      },
      {
        "code": "210400",
        "region": "抚顺市"
      },
      {
        "code": "210500",
        "region": "本溪市"
      },
      {
        "code": "210600",
        "region": "丹东市"
      },
      {
        "code": "210700",
        "region": "锦州市"
      },
      {
        "code": "210800",
        "region": "营口市"
      },
      {
        "code": "210900",
        "region": "阜新市"
      },
      {
        "code": "211000",
        "region": "辽阳市"
      },
      {
        "code": "211100",
        "region": "盘锦市"
      },
      {
        "code": "211200",
        "region": "铁岭市"
      },
      {
        "code": "211300",
        "region": "朝阳市"
      },
      {
        "code": "211400",
        "region": "葫芦岛市"
      }
    ]
  },
  {
    "code": "220000",
    "region": "吉林省",
    "regionEntitys": [
      {
        "code": "220100",
        "region": "长春市"
      },
      {
        "code": "220200",
        "region": "吉林市"
      },
      {
        "code": "220300",
        "region": "四平市"
      },
      {
        "code": "220400",
        "region": "辽源市"
      },
      {
        "code": "220500",
        "region": "通化市"
      },
      {
        "code": "220600",
        "region": "白山市"
      },
      {
        "code": "220700",
        "region": "松原市"
      },
      {
        "code": "220800",
        "region": "白城市"
      },
      {
        "code": "222400",
        "region": "延边朝鲜族自治州"
      }
    ]
  },
  {
    "code": "230000",
    "region": "黑龙江省",
    "regionEntitys": [
      {
        "code": "230100",
        "region": "哈尔滨市"
      },
      {
        "code": "230200",
        "region": "齐齐哈尔市"
      },
      {
        "code": "230300",
        "region": "鸡西市"
      },
      {
        "code": "230400",
        "region": "鹤岗市"
      },
      {
        "code": "230500",
        "region": "双鸭山市"
      },
      {
        "code": "230600",
        "region": "大庆市"
      },
      {
        "code": "230700",
        "region": "伊春市"
      },
      {
        "code": "230800",
        "region": "佳木斯市"
      },
      {
        "code": "230900",
        "region": "七台河市"
      },
      {
        "code": "231000",
        "region": "牡丹江市"
      },
      {
        "code": "231100",
        "region": "黑河市"
      },
      {
        "code": "231200",
        "region": "绥化市"
      },
      {
        "code": "232700",
        "region": "大兴安岭地区"
      }
    ]
  },
  {
    "code": "310000",
    "region": "上海市",
    "regionEntitys": [
      {
        "code": "310000",
        "region": "上海市"       
      }
    ]
  },
  {
    "code": "320000",
    "region": "江苏省",
    "regionEntitys": [
      {
        "code": "320100",
        "region": "南京市"
      },
      {
        "code": "320200",
        "region": "无锡市"
      },
      {
        "code": "320300",
        "region": "徐州市"
      },
      {
        "code": "320400",
        "region": "常州市"
      },
      {
        "code": "320500",
        "region": "苏州市"
      },
      {
        "code": "320600",
        "region": "南通市"
      },
      {
        "code": "320700",
        "region": "连云港市"
      },
      {
        "code": "320800",
        "region": "淮安市"
      },
      {
        "code": "320900",
        "region": "盐城市"
      },
      {
        "code": "321000",
        "region": "扬州市"
      },
      {
        "code": "321100",
        "region": "镇江市"
      },
      {
        "code": "321200",
        "region": "泰州市"
      },
      {
        "code": "321300",
        "region": "宿迁市"
      }
    ]
  },
  {
    "code": "330000",
    "region": "浙江省",
    "regionEntitys": [
      {
        "code": "330100",
        "region": "杭州市"
      },
      {
        "code": "330200",
        "region": "宁波市"
      },
      {
        "code": "330300",
        "region": "温州市"
      },
      {
        "code": "330400",
        "region": "嘉兴市"
      },
      {
        "code": "330500",
        "region": "湖州市"
      },
      {
        "code": "330600",
        "region": "绍兴市"
      },
      {
        "code": "330700",
        "region": "金华市"
      },
      {
        "code": "330800",
        "region": "衢州市"
      },
      {
        "code": "330900",
        "region": "舟山市"
      },
      {
        "code": "331000",
        "region": "台州市"
      },
      {
        "code": "331100",
        "region": "丽水市"
      }
    ]
  },
  {
    "code": "340000",
    "region": "安徽省",
    "regionEntitys": [
      {
        "code": "340100",
        "region": "合肥市"
      },
      {
        "code": "340200",
        "region": "芜湖市"
      },
      {
        "code": "340300",
        "region": "蚌埠市"
      },
      {
        "code": "340400",
        "region": "淮南市"
      },
      {
        "code": "340500",
        "region": "马鞍山市"
      },
      {
        "code": "340600",
        "region": "淮北市"
      },
      {
        "code": "340700",
        "region": "铜陵市"
      },
      {
        "code": "340800",
        "region": "安庆市"
      },
      {
        "code": "341000",
        "region": "黄山市"
      },
      {
        "code": "341100",
        "region": "滁州市"
      },
      {
        "code": "341200",
        "region": "阜阳市"
      },
      {
        "code": "341300",
        "region": "宿州市"
      },
      {
        "code": "341500",
        "region": "六安市"
      },
      {
        "code": "341600",
        "region": "亳州市"
      },
      {
        "code": "341700",
        "region": "池州市"
      },
      {
        "code": "341800",
        "region": "宣城市"
      }
    ]
  },
  {
    "code": "350000",
    "region": "福建省",
    "regionEntitys": [
      {
        "code": "350100",
        "region": "福州市"
      },
      {
        "code": "350200",
        "region": "厦门市"
      },
      {
        "code": "350300",
        "region": "莆田市"
      },
      {
        "code": "350400",
        "region": "三明市"
      },
      {
        "code": "350500",
        "region": "泉州市"
      },
      {
        "code": "350600",
        "region": "漳州市"
      },
      {
        "code": "350700",
        "region": "南平市"
      },
      {
        "code": "350800",
        "region": "龙岩市"
      },
      {
        "code": "350900",
        "region": "宁德市"
      }
    ]
  },
  {
    "code": "360000",
    "region": "江西省",
    "regionEntitys": [
      {
        "code": "360100",
        "region": "南昌市"
      },
      {
        "code": "360200",
        "region": "景德镇市"
      },
      {
        "code": "360300",
        "region": "萍乡市"
      },
      {
        "code": "360400",
        "region": "九江市"
      },
      {
        "code": "360500",
        "region": "新余市"
      },
      {
        "code": "360600",
        "region": "鹰潭市"
      },
      {
        "code": "360700",
        "region": "赣州市"
      },
      {
        "code": "360800",
        "region": "吉安市"
      },
      {
        "code": "360900",
        "region": "宜春市"
      },
      {
        "code": "361000",
        "region": "抚州市"
      },
      {
        "code": "361100",
        "region": "上饶市"
      }
    ]
  },
  {
    "code": "370000",
    "region": "山东省",
    "regionEntitys": [
      {
        "code": "370100",
        "region": "济南市"
      },
      {
        "code": "370200",
        "region": "青岛市"
      },
      {
        "code": "370300",
        "region": "淄博市"
      },
      {
        "code": "370400",
        "region": "枣庄市"
      },
      {
        "code": "370500",
        "region": "东营市"
      },
      {
        "code": "370600",
        "region": "烟台市"
      },
      {
        "code": "370700",
        "region": "潍坊市"
      },
      {
        "code": "370800",
        "region": "济宁市"
      },
      {
        "code": "370900",
        "region": "泰安市"
      },
      {
        "code": "371000",
        "region": "威海市"
      },
      {
        "code": "371100",
        "region": "日照市"
      },
      {
        "code": "371200",
        "region": "莱芜市"
      },
      {
        "code": "371300",
        "region": "临沂市"
      },
      {
        "code": "371400",
        "region": "德州市"
      },
      {
        "code": "371500",
        "region": "聊城市"
      },
      {
        "code": "371600",
        "region": "滨州市"
      },
      {
        "code": "371700",
        "region": "菏泽市"
      }
    ]
  },
  {
    "code": "410000",
    "region": "河南省",
    "regionEntitys": [
      {
        "code": "410100",
        "region": "郑州市"
      },
      {
        "code": "410200",
        "region": "开封市"
      },
      {
        "code": "410300",
        "region": "洛阳市"
      },
      {
        "code": "410400",
        "region": "平顶山市"
      },
      {
        "code": "410500",
        "region": "安阳市"
      },
      {
        "code": "410600",
        "region": "鹤壁市"
      },
      {
        "code": "410700",
        "region": "新乡市"
      },
      {
        "code": "410800",
        "region": "焦作市"
      },
      {
        "code": "410900",
        "region": "濮阳市"
      },
      {
        "code": "411000",
        "region": "许昌市"
      },
      {
        "code": "411100",
        "region": "漯河市"
      },
      {
        "code": "411200",
        "region": "三门峡市"
      },
      {
        "code": "411300",
        "region": "南阳市"
      },
      {
        "code": "411400",
        "region": "商丘市"
      },
      {
        "code": "411500",
        "region": "信阳市"
      },
      {
        "code": "411600",
        "region": "周口市"
      },
      {
        "code": "411700",
        "region": "驻马店市"
      },
      {
        "code": "419000",
        "region": "省直辖县级行政区划"
      }
    ]
  },
  {
    "code": "420000",
    "region": "湖北省",
    "regionEntitys": [
      {
        "code": "420100",
        "region": "武汉市"
      },
      {
        "code": "420200",
        "region": "黄石市"
      },
      {
        "code": "420300",
        "region": "十堰市"
      },
      {
        "code": "420500",
        "region": "宜昌市"
      },
      {
        "code": "420600",
        "region": "襄阳市"
      },
      {
        "code": "420700",
        "region": "鄂州市"
      },
      {
        "code": "420800",
        "region": "荆门市"
      },
      {
        "code": "420900",
        "region": "孝感市"
      },
      {
        "code": "421000",
        "region": "荆州市"
      },
      {
        "code": "421100",
        "region": "黄冈市"
      },
      {
        "code": "421200",
        "region": "咸宁市"
      },
      {
        "code": "421300",
        "region": "随州市"
      },
      {
        "code": "422800",
        "region": "恩施土家族苗族自治州"
      },
      {
        "code": "429000",
        "region": "省直辖县级行政区划"
      }
    ]
  },
  {
    "code": "430000",
    "region": "湖南省",
    "regionEntitys": [
      {
        "code": "430100",
        "region": "长沙市"
      },
      {
        "code": "430200",
        "region": "株洲市"
      },
      {
        "code": "430300",
        "region": "湘潭市"
      },
      {
        "code": "430400",
        "region": "衡阳市"
      },
      {
        "code": "430500",
        "region": "邵阳市"
      },
      {
        "code": "430600",
        "region": "岳阳市"
      },
      {
        "code": "430700",
        "region": "常德市"
      },
      {
        "code": "430800",
        "region": "张家界市"
      },
      {
        "code": "430900",
        "region": "益阳市"
      },
      {
        "code": "431000",
        "region": "郴州市"
      },
      {
        "code": "431100",
        "region": "永州市"
      },
      {
        "code": "431200",
        "region": "怀化市"
      },
      {
        "code": "431300",
        "region": "娄底市"
      },
      {
        "code": "433100",
        "region": "湘西土家族苗族自治州"
      }
    ]
  },
  {
    "code": "440000",
    "region": "广东省",
    "regionEntitys": [
      {
        "code": "440100",
        "region": "广州市"
      },
      {
        "code": "440200",
        "region": "韶关市"
      },
      {
        "code": "440300",
        "region": "深圳市"
      },
      {
        "code": "440400",
        "region": "珠海市"
      },
      {
        "code": "440500",
        "region": "汕头市"
      },
      {
        "code": "440600",
        "region": "佛山市"
      },
      {
        "code": "440700",
        "region": "江门市"
      },
      {
        "code": "440800",
        "region": "湛江市"
      },
      {
        "code": "440900",
        "region": "茂名市"
      },
      {
        "code": "441200",
        "region": "肇庆市"
      },
      {
        "code": "441300",
        "region": "惠州市"
      },
      {
        "code": "441400",
        "region": "梅州市"
      },
      {
        "code": "441500",
        "region": "汕尾市"
      },
      {
        "code": "441600",
        "region": "河源市"
      },
      {
        "code": "441700",
        "region": "阳江市"
      },
      {
        "code": "441800",
        "region": "清远市"
      },
      {
        "code": "441900",
        "region": "东莞市"
      },
      {
        "code": "442000",
        "region": "中山市"
      },
      {
        "code": "445100",
        "region": "潮州市"
      },
      {
        "code": "445200",
        "region": "揭阳市"
      },
      {
        "code": "445300",
        "region": "云浮市"
      }
    ]
  },
  {
    "code": "450000",
    "region": "广西壮族自治区",
    "regionEntitys": [
      {
        "code": "450100",
        "region": "南宁市"
      },
      {
        "code": "450200",
        "region": "柳州市"
      },
      {
        "code": "450300",
        "region": "桂林市"
      },
      {
        "code": "450400",
        "region": "梧州市"
      },
      {
        "code": "450500",
        "region": "北海市"
      },
      {
        "code": "450600",
        "region": "防城港市"
      },
      {
        "code": "450700",
        "region": "钦州市"
      },
      {
        "code": "450800",
        "region": "贵港市"
      },
      {
        "code": "450900",
        "region": "玉林市"
      },
      {
        "code": "451000",
        "region": "百色市"
      },
      {
        "code": "451100",
        "region": "贺州市"
      },
      {
        "code": "451200",
        "region": "河池市"
      },
      {
        "code": "451300",
        "region": "来宾市"
      },
      {
        "code": "451400",
        "region": "崇左市"
      }
    ]
  },
  {
    "code": "460000",
    "region": "海南省",
    "regionEntitys": [
      {
        "code": "460100",
        "region": "海口市"
      },
      {
        "code": "460200",
        "region": "三亚市"
      },
      {
        "code": "460300",
        "region": "三沙市"
      },
      {
        "code": "460400",
        "region": "儋州市"
      },
      {
        "code": "469000",
        "region": "省直辖县级行政区划"
      }
    ]
  },
  {
    "code": "500000",
    "region": "重庆市",
    "regionEntitys": [
      {
        "code": "500100",
        "region": "市辖区"
      },
      {
        "code": "500200",
        "region": "县"
      }
    ]
  },
  {
    "code": "510000",
    "region": "四川省",
    "regionEntitys": [
      {
        "code": "510100",
        "region": "成都市"
      },
      {
        "code": "510300",
        "region": "自贡市"
      },
      {
        "code": "510400",
        "region": "攀枝花市"
      },
      {
        "code": "510500",
        "region": "泸州市"
      },
      {
        "code": "510600",
        "region": "德阳市"
      },
      {
        "code": "510700",
        "region": "绵阳市"
      },
      {
        "code": "510800",
        "region": "广元市"
      },
      {
        "code": "510900",
        "region": "遂宁市"
      },
      {
        "code": "511000",
        "region": "内江市"
      },
      {
        "code": "511100",
        "region": "乐山市"
      },
      {
        "code": "511300",
        "region": "南充市"
      },
      {
        "code": "511400",
        "region": "眉山市"
      },
      {
        "code": "511500",
        "region": "宜宾市"
      },
      {
        "code": "511600",
        "region": "广安市"
      },
      {
        "code": "511700",
        "region": "达州市"
      },
      {
        "code": "511800",
        "region": "雅安市"
      },
      {
        "code": "511900",
        "region": "巴中市"
      },
      {
        "code": "512000",
        "region": "资阳市"
      },
      {
        "code": "513200",
        "region": "阿坝藏族羌族自治州"
      },
      {
        "code": "513300",
        "region": "甘孜藏族自治州"
      },
      {
        "code": "513400",
        "region": "凉山彝族自治州"
      }
    ]
  },
  {
    "code": "520000",
    "region": "贵州省",
    "regionEntitys": [
      {
        "code": "520100",
        "region": "贵阳市"
      },
      {
        "code": "520200",
        "region": "六盘水市"
      },
      {
        "code": "520300",
        "region": "遵义市"
      },
      {
        "code": "520400",
        "region": "安顺市"
      },
      {
        "code": "520500",
        "region": "毕节市"
      },
      {
        "code": "520600",
        "region": "铜仁市"
      },
      {
        "code": "522300",
        "region": "黔西南布依族苗族自治州"
      },
      {
        "code": "522600",
        "region": "黔东南苗族侗族自治州"
      },
      {
        "code": "522700",
        "region": "黔南布依族苗族自治州"
      }
    ]
  },
  {
    "code": "530000",
    "region": "云南省",
    "regionEntitys": [
      {
        "code": "530100",
        "region": "昆明市"
      },
      {
        "code": "530300",
        "region": "曲靖市"
      },
      {
        "code": "530400",
        "region": "玉溪市"
      },
      {
        "code": "530500",
        "region": "保山市"
      },
      {
        "code": "530600",
        "region": "昭通市"
      },
      {
        "code": "530700",
        "region": "丽江市"
      },
      {
        "code": "530800",
        "region": "普洱市"
      },
      {
        "code": "530900",
        "region": "临沧市"
      },
      {
        "code": "532300",
        "region": "楚雄彝族自治州"
      },
      {
        "code": "532500",
        "region": "红河哈尼族彝族自治州"
      },
      {
        "code": "532600",
        "region": "文山壮族苗族自治州"
      },
      {
        "code": "532800",
        "region": "西双版纳傣族自治州"
      },
      {
        "code": "532900",
        "region": "大理白族自治州"
      },
      {
        "code": "533100",
        "region": "德宏傣族景颇族自治州"
      },
      {
        "code": "533300",
        "region": "怒江傈僳族自治州"
      },
      {
        "code": "533400",
        "region": "迪庆藏族自治州"
      }
    ]
  },
  {
    "code": "540000",
    "region": "西藏自治区",
    "regionEntitys": [
      {
        "code": "540100",
        "region": "拉萨市"
      },
      {
        "code": "540200",
        "region": "日喀则市"
      },
      {
        "code": "540300",
        "region": "昌都市"
      },
      {
        "code": "540400",
        "region": "林芝市"
      },
      {
        "code": "540500",
        "region": "山南市"
      },
      {
        "code": "542400",
        "region": "那曲地区"
      },
      {
        "code": "542500",
        "region": "阿里地区"
      }
    ]
  },
  {
    "code": "610000",
    "region": "陕西省",
    "regionEntitys": [
      {
        "code": "610100",
        "region": "西安市"
      },
      {
        "code": "610200",
        "region": "铜川市"
      },
      {
        "code": "610300",
        "region": "宝鸡市"
      },
      {
        "code": "610400",
        "region": "咸阳市"
      },
      {
        "code": "610500",
        "region": "渭南市"
      },
      {
        "code": "610600",
        "region": "延安市"
      },
      {
        "code": "610700",
        "region": "汉中市"
      },
      {
        "code": "610800",
        "region": "榆林市"
      },
      {
        "code": "610900",
        "region": "安康市"
      },
      {
        "code": "611000",
        "region": "商洛市"
      }
    ]
  },
  {
    "code": "620000",
    "region": "甘肃省",
    "regionEntitys": [
      {
        "code": "620100",
        "region": "兰州市"
      },
      {
        "code": "620200",
        "region": "嘉峪关市"
      },
      {
        "code": "620300",
        "region": "金昌市"
      },
      {
        "code": "620400",
        "region": "白银市"
      },
      {
        "code": "620500",
        "region": "天水市"
      },
      {
        "code": "620600",
        "region": "武威市"
      },
      {
        "code": "620700",
        "region": "张掖市"
      },
      {
        "code": "620800",
        "region": "平凉市"
      },
      {
        "code": "620900",
        "region": "酒泉市"
      },
      {
        "code": "621000",
        "region": "庆阳市"
      },
      {
        "code": "621100",
        "region": "定西市"
      },
      {
        "code": "621200",
        "region": "陇南市"
      },
      {
        "code": "622900",
        "region": "临夏回族自治州"
      },
      {
        "code": "623000",
        "region": "甘南藏族自治州"
      }
    ]
  },
  {
    "code": "630000",
    "region": "青海省",
    "regionEntitys": [
      {
        "code": "630100",
        "region": "西宁市"
      },
      {
        "code": "630200",
        "region": "海东市"
      },
      {
        "code": "632200",
        "region": "海北藏族自治州"
      },
      {
        "code": "632300",
        "region": "黄南藏族自治州"
      },
      {
        "code": "632500",
        "region": "海南藏族自治州"
      },
      {
        "code": "632600",
        "region": "果洛藏族自治州"
      },
      {
        "code": "632700",
        "region": "玉树藏族自治州"
      },
      {
        "code": "632800",
        "region": "海西蒙古族藏族自治州"
      }
    ]
  },
  {
    "code": "640000",
    "region": "宁夏回族自治区",
    "regionEntitys": [
      {
        "code": "640100",
        "region": "银川市"       
      },
      {
        "code": "640200",
        "region": "石嘴山市"
      },
      {
        "code": "640300",
        "region": "吴忠市"
      },
      {
        "code": "640400",
        "region": "固原市"
      },
      {
        "code": "640500",
        "region": "中卫市"
      }
    ]
  },
  {
    "code": "650000",
    "region": "新疆维吾尔自治区",
    "regionEntitys": [
      {
        "code": "650100",
        "region": "乌鲁木齐市"
      },
      {
        "code": "650200",
        "region": "克拉玛依市"
      },
      {
        "code": "650400",
        "region": "吐鲁番市"
      },
      {
        "code": "650500",
        "region": "哈密市"
      },
      {
        "code": "652300",
        "region": "昌吉回族自治州"
      },
      {
        "code": "652700",
        "region": "博尔塔拉蒙古自治州"
      },
      {
        "code": "652800",
        "region": "巴音郭楞蒙古自治州"
      },
      {
        "code": "652900",
        "region": "阿克苏地区"
      },
      {
        "code": "653000",
        "region": "克孜勒苏柯尔克孜自治州"
      },
      {
        "code": "653100",
        "region": "喀什地区"
      },
      {
        "code": "653200",
        "region": "和田地区"
      },
      {
        "code": "654000",
        "region": "伊犁哈萨克自治州"
      },
      {
        "code": "654200",
        "region": "塔城地区"
      },
      {
        "code": "654300",
        "region": "阿勒泰地区"
      },
      {
        "code": "659000",
        "region": "自治区直辖县级行政区划"
      }
    ]
  },
  {
    "code": "710000",
    "region": "台湾省"
  },
  {
    "code": "810000",
    "region": "香港特别行政区"
  },
  {
    "code": "820000",
    "region": "澳门特别行政区"
  }
]

export {cityCodeList}