<template>
  <div class="wrap">
    <div class="login">
      <div class="title">您好！欢迎来到
        <hr>养帆起航管理后台
      </div>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleFormRef" label-width="80px" class="demo-ruleForm">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="ruleForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm" class="login-btn">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
	<div class="beian">
		<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023002820号</a>
	</div>
		
  </div>
</template>
<script>
import { setToken, removeToken } from '@/utils/token.js'
import { login } from '@/api/api'
export default {
  data() {
    return {
      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    removeToken('token')
    removeToken('username')
    removeToken('userId')
  },
  methods: {
    submitForm() {
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          const data = {
            password: window.btoa(this.ruleForm.password),
            username: this.ruleForm.username
          }
          login(data).then(res => {
            if (res.code === 200) {
              this.$router.push({
                name: 'index'
              })
              setToken('token', res.token)
              setToken('userId', res.userId)
              setToken('username', res.username)
              setToken('isLogin', true)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}

</script>
<style scoped lang="scss">
.wrap {
  width: 100%;
  height: 100vh;
  background: url('../assets/image/login-bg.png') no-repeat center center;
  background-size: 100% 100vh;
  overflow: hidden;

  .login {
    width: 350px;
    margin-left: 56%;
    margin-top: 12%;

    .title {
      font-size: 28px;
      font-weight: 500;
      background-image: linear-gradient(265deg, #3794fc 0%, #0a67f3 100%);
      color: transparent;
      -webkit-background-clip: text;
      margin-bottom: 30px;
      margin-left: 80px;
    }

    .login-btn {
      width: 270px;
      background: linear-gradient(265deg, #3794fc 0%, #0a67f3 100%);
    }
	
  }
  .beian{
  	    text-align: center;
  	    margin-left: 50%;
  	    margin-top: 12%;
  	    font-size: 14px;
  	
  }
}
</style>