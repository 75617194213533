<template>
    <div class="breadcrumb">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item v-if="item.meta.ChineseName !== '首页'" v-for="(item, index) in $route.matched"
                :key="item.id">
                {{ item.meta.ChineseName }}</el-breadcrumb-item>
            <el-breadcrumb-item style="width:100%" v-if="item.meta.ChineseName === '首页'"
                v-for="(item, index) in $route.matched" :key="item.id">
                <div class="index">
                    <div class="index-left">
                        <img src="../../assets/image/logo.png" alt="">
                        <span>欢迎使用养帆起航管理系统</span>
                    </div>
                    <div class="index-right">
                        <div class="index-vip" style="cursor: pointer;" @click="handleVip">
                            <div class="index-title">会员总数</div>
                            <div class="index-number">{{ list.user_amount }}</div>
                        </div>
                        <div class="hr"></div>
                        <div class="index-vip" style="cursor: pointer;" @click="handleOrder">
                            <div class="index-title">待完结订单数</div>
                            <div class="index-number">{{ list.order_amount }}</div>
                        </div>
                        <div class="hr"></div>
                        <div class="index-vip" style="border:none">
                            <div class="index-title">产品总访问量</div>
                            <div class="index-number" style="color:#666;">{{ list.total_amount }}</div>
                        </div>
                    </div>
                </div>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
import { getIndex } from '@/api/api'
export default {
    data() {
        return {
            list: {},
        }
    },
    created() {
    },
    mounted() {
        if (this.$route.meta.ChineseName == '首页') {
            this.onList()
        }
    },
    methods: {
        onList() {
            getIndex().then(res => {
                if (res.code == 200) {
                    this.list = res.data
                }
            })
        },
        handleOrder() {
            this.$router.push({
                name: 'orderList'
            })
        },
        handleVip() {
            this.$router.push({
                name: 'userList'
            })
        },
    }
}
</script>
<style scoped lang="scss">
.breadcrumb {

    width: 96%;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
}

.index {
    display: flex;
    justify-content: space-between;

    .index-left {
        img {
            width: 80px;
            height: 80px;
        }

        span {
            font-size: 14px;
        }
    }

    .index-right {
        display: flex;
        align-items: center;

        .hr {
            width: 1px;
            height: 40px;
            background-color: #e5e0e0;
        }

        .index-vip {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;


            .index-title {
                color: #666;
                font-size: 12px;
            }

            .index-number {
                color: #4eb9eb;
                font-size: 26px;
                margin-top: 10px;
            }
        }


    }

}
</style>