<template>
    <el-button type="danger" plain size="mini" class="delete-btn" @click="handleDeleteClick"
        :id="`deleteBtn${id}`">删除</el-button>
</template>
<script>
export default {
    props: {
        deleteTips: {
            type: String,
            default: '此操作将永久删除, 是否继续?'
        },
        id: [Number, String],
        index: Number,
        tname: String
    },
    data() {
        return {}
    },
    methods: {
        handleDeleteClick() {
            this.$confirm(this.deleteTips, '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('handleDeleteClick', this.id, this.index, this.tname)
            })
        }
    }
}
</script>
<style lang="scss" scoped>

</style>