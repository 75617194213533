<template>
    <div>
        <el-button type="primary" size="medium" class="el-icon-plus" @click="handleClick(false, '')">新建退货地址</el-button>
        <div style="margin-top: 10px;">
            <el-table border :data="tableData" v-loading="loading">
                <el-table-column type="index" label="序号" width="60px" align="center">
                    <template slot-scope="scope">
                        <span class="model-name">{{ scope.$index + 1 }}</span>
                        <el-tag effect="dark" size="mini" v-if="scope.row.is_default">默认</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="收货人" align="center"></el-table-column>
                <el-table-column prop="phone" label="联系方式" align="center"></el-table-column>
                <el-table-column prop="address" label="退货地址" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.province }},{{ scope.row.city }},{{ scope.row.district }},{{
                            scope.row.place }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="handleClick(true, scope.row)"
                            style="margin-right: 10px;">编辑</el-button>
                        <deleteBtn @handleDeleteClick="handleDeleteClick" :id="scope.row.id" :index="scope.$index"
                            style="margin-right: 10px;" />
                        <el-button v-if="!scope.row.is_default" type="success" plain size="mini" class="default-btn"
                            @click="handleSetDefault(scope.row)">设为默认</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper" style="margin-top:20px">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>
        <div>
            <el-dialog :title="isEditAuth ? '编辑地址' : '新建地址'" :visible.sync="dialogConfirmForm" width="800px">
                <el-form :model="formLine" :rules="dialogRules" ref="dialogForm" label-width="120px">
                    <el-form-item label="收货人" prop="name" required>
                        <el-input v-model.trim="formLine.name" class="form-freight" maxlength="20" show-word-limit
                            placeholder="请输入" size="medium"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式" prop="phone" required>
                        <el-input v-model.trim="formLine.phone" class="form-freight" placeholder="请输入" size="medium"
                            maxlength="13"></el-input>
                        <span>座机样例：0571-57897483</span>
                    </el-form-item>
                    <el-form-item label="所在地" prop="address" required>
                        <el-cascader v-model="formLine.address" :options="cityOptions"></el-cascader>
                    </el-form-item>
                    <el-form-item label="详细地址" prop="place" required>
                        <el-input v-model.trim="formLine.place" class="form-freight" maxlength="50" show-word-limit
                            placeholder="请输入" size="medium"></el-input>
                    </el-form-item>
                    <el-form-item label="设为默认" required v-if="isEditAuth">
                        <el-switch v-model="formLine.is_default" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogConfirmForm = false">取 消</el-button>
                    <el-button type="primary" @click="submitDialogForm()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import deleteBtn from '@/components/base/deleteBtn'
import { cityList } from '@/utils/city'
import { getRefund, addRefund, editRefund, delRefund, editDefault } from "@/api/api.js"
export default {
    components: {
        deleteBtn
    },
    data() {
        return {
            tableData: [],
            isEditAuth: false,
            dialogConfirmForm: false,
            formLine: {},
            dialogRules: {
                name: [{ required: true, message: '请输入名称' }],
                phone: [{ required: true, message: '请输入联系方式' }],
                address: [{ required: true, message: '请选择省市区' }],
                place: [{ required: true, message: '请输入地址' }],
            },
            cityOptions: [],
            loading: false,
            currentPage: 1,
            totalPage: 1,

        }
    },
    mounted() {
        this.cityOptions = cityList
        this.onList()
    },
    methods: {
        onList() {
            this.loading = false
            const data = {
                p: this.currentPage
            }
            getRefund(data).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                    this.totalPage = res.tpage
                } else {
                    this.tableData = []
                }
            })
        },
        handleClick(isEdit, data) {
            this.isEditAuth = isEdit
            this.dialogConfirmForm = true
            if (data) {
                this.formLine = Object.assign({}, data)
                let addressList = []
                addressList.push(data.province, data.city, data.district)
                this.formLine.address = addressList
            } else {
                this.formLine = {}
                this.$nextTick(() => {
                    this.$refs.dialogForm.clearValidate()
                })
            }
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },
        handleDeleteClick(id) {
            let id_list = []
            id_list.push(id)
            delRefund({ id_list: id_list }).then(res => {
                if (res.code === 200) {
                    this.onList()
                }
            })
        },
        handleSetDefault(row) {
            this.$confirm('确认将此条数据设为默认？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                editDefault({
                    id: row.id,
                }).then(res => {
                    if (res.code === 200) {
                        this.onList()
                    }
                })
            })
        },
        submitDialogForm() {
            this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    const data = {
                        name: this.formLine.name,
                        phone: this.formLine.phone,
                        place: this.formLine.place,
                        province: this.formLine.address[0],
                        city: this.formLine.address[1],
                        district: this.formLine.address[2],
                    }
                    if (this.isEditAuth) {
                        data.id = this.formLine.id
                        data.is_default = this.formLine.is_default
                        editRefund(data).then(res => {
                            if (res.code === 200) {
                                this.onList()
                            }
                        })
                    } else {
                        addRefund(data).then(res => {
                            if (res.code === 200) {
                                this.onList()
                            }
                        })
                    }
                    this.dialogConfirmForm = false
                } else {
                    return false
                }
            });
        }

    }
}

</script>
<style lang="scss" scoped></style>