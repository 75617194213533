<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="sortInputForm" class="demo-ruleForm"
            :label-width="`${labelWidth}px`">
            <el-form-item label="排序" prop="num" :required="isRequired">
                <el-input v-model="ruleForm.num" @input="onSortInput" @blur="sortInputBlur" maxlength="5"
                    show-word-limit />
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        isRequired: {
            type: Boolean,
            default: true
        },
        ruleForm: {
            type: Object,
            default: function () {
                return { num: '' }
            }
        },
        labelWidth: {
            type: String,
            default: '100'
        }
    },
    data() {
        const num = (rule, value, callback) => {
            console.log('value', value)
            if (value === "" || value == undefined) {
                callback(new Error(`请输入排序!`));
            } else if (value < 1) {
                callback(new Error(`排序不能小于1`));
            } else if (/^-?[1-9]\d*$/.test(value) == false) {
                callback(new Error("请输入正整数"));
            } else {
                callback();
            }
        };
        return {
            rules: {
                num: [{ validator: num }],
            },
        }
    },
    methods: {
        onSortInput() {
            this.$emit('onSortInput', this.ruleForm.num)
        },
        sortInputBlur() {
            this.$emit('sortInputBlur')
        },
        validateForm() {
            let flag = false
            this.$refs['sortInputForm'].validate((valid) => {
                flag = valid
            })
            return flag
        }
    }
}
</script>

<style lang="sass" scoped>
</style>