<template>
    <div>
        <div>
            <el-form :inline="true" :model="formInline" class="search-form">
                <el-form-item label="SPU编码">
                    <el-select v-model="formInline.spu_code" filterable placeholder="请选择" @focus="handleSpuList">
                        <el-option v-for="item in spuList" :key="item.spu_code" :label="item.spu_code"
                            :value="item.spu_code">
                            <span style="float: left">{{ item.spu_code }}</span>
                            <span class="goods_name">{{ item.goods_name }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="handleSearchClick" icon="el-icon-search">查询</el-button>
                    <el-button size="medium" @click="handleResetClick" icon="el-icon-refresh">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div><el-button type="primary" size="medium" @click="handleToAddPage()" icon="el-icon-plus">添加</el-button>
        </div>
        <div style="margin-top:20px">
            <el-table :data="tableData" style="width: 96%" v-loading="loading" border>
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
                <el-table-column prop="nickname" label="昵称" align="center"></el-table-column>
                <el-table-column prop="content" label="内容" align="center">
                    <template slot-scope="scope">
                        <div v-html="scope.row.content" style="max-height: 160px;"></div>
                    </template>
                </el-table-column>
                <el-table-column prop="spu_code" label="商品编码" align="center"></el-table-column>
                <el-table-column prop="avatar_url" label="用户头像" align="center">
                    <template slot-scope="scope">
                        <imageView :src="scope.row.avatar_url" class="image" />
                    </template>
                </el-table-column>
                <el-table-column prop="show_time" label="显示时间" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div style="display:flex">
                            <!-- <el-button type="primary" plain size="mini"
                                @click="handleToAddPage(scope.row)">编辑</el-button> -->
                            <deleteBtn @handleDeleteClick="handleDeleteClick" :id="scope.row.id" :index="scope.$index" />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import { getComment, delComment, getSpu } from '@/api/api'
import deleteBtn from '@/components/base/deleteBtn.vue'
import imageView from '@/components/base/image.vue'
export default {
    components: {
        deleteBtn, imageView
    },
    data() {
        return {
            formInline: {},
            tableData: [],
            currentPage: 1,
            totalPage: 1,
            page: 1,
            loading: true,
            username: '',
            spuList: [],
        }
    },
    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            const data = this.formInline
            data.p = this.currentPage
            getComment(data).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                }
            })
        },
        handleSpuList() {
            getSpu().then(res => {
                if (res.code === 200) {
                    this.spuList = res.data
                }
            })
        },
        handleSearchClick() {
            this.onList()
        },
        handleResetClick() {
            this.currentPage = 1
            this.formInline = {}
            this.onList()
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },

        handleToAddPage(data) {
            if (data) {
                const row = {
                    name: data.name,
                    id: data.id,
                    username: data.username,
                    state: data.state ? 1 : 0
                }
                this.$router.push({
                    name: 'editComment',
                    query: {
                        data: row
                    }
                })
            } else {
                this.$router.push({
                    name: 'addComment',
                })
            }
        },
        handleDeleteClick(id, index) {
            const id_list = []
            id_list.push(id)
            delComment({
                id_list: id_list
            }).then(res => {
                if (res.code === 200) {
                    this.$message.success('删除成功！')
                    this.onList()
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.pagination-wrapper {
    margin-top: 10px;
}

.goods_name {
    float: right;
    color: #8492a6;
    font-size: 13px;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.image {
    width: 80px;
    height: 80px;
}
</style>