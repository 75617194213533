<template>
    <div class="home">
        <echartsView />
        <barChart />
    </div>
</template>

<script>
import { getIndex } from '@/api/api'
import barChart from '@/components/echarts/barChart.vue'
import echartsView from '@/components/echarts/lineChart.vue'
export default {
    components: {
        echartsView, barChart
    },
    data() {
        return {
            list: {},
        }
    },
    created() {

    },
    mounted() {
        // this.onList()
    },
    methods: {
        onList() {
            getIndex().then(res => {
                if (res.code == 200) {
                    this.list = res.data
                }
            })
        },
        handleOrder() {
            this.$router.push({
                name: 'orderList'
            })
        },
        handleVip() {
            this.$router.push({
                name: 'userList'
            })
        },
    }
}
</script>
<style scoped lang="scss">
.index {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .index-left {
        img {
            width: 80px;
            height: 80px;
        }

        span {
            font-size: 14px;
        }
    }

    .index-right {
        display: flex;
        align-items: center;

        .hr {
            width: 1px;
            height: 40px;
            background-color: #e5e0e0;
        }

        .index-vip {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;

            .index-title {
                color: #666;
                font-size: 12px;
            }

            .index-number {
                color: #4eb9eb;
                font-size: 26px;
                margin-top: 10px;
            }
        }


    }

}
</style>