<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="banner名称" prop="title" required>
                <el-input v-model.trim="ruleForm.title" class="form-item" placeholder="请输入" maxlength="30"
                    show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="展示类型" prop="is_img" required>
                <el-select v-model="ruleForm.is_img" placeholder="请选择" class="form-item" @change="handleIsImg">
                    <el-option label="图片" :value="1"></el-option>
                    <el-option label="视频" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="banner图片" required v-if="ruleForm.is_img === 1">
                <upLoadImage :images="ruleForm.img" :maxlength="1" @uploadRemoveImage="uploadRemoveImage"
                    @uploadMoreSuccess="uploadSuccess" tips="建议上传尺寸为1500 * 720 像素" />
            </el-form-item>
            <el-form-item label="商品视频" required v-if="ruleForm.is_img === 0">
                <upLoadFiles :limit="1" :images="ruleForm.img" accept=".mp4" @uploadFileStart="uploadVideoStart"
                    @uploadFile="uploadFileVideo" @handleRemove="handleRemoveVideo" id="qny_video_url" />
            </el-form-item>
            <el-form-item label="跳转类型" prop="type" required>
                <el-select v-model="ruleForm.type" placeholder="请选择" class="form-item" @change="handleType">
                    <el-option label="商品" :value="1"></el-option>
                    <el-option label="不跳转" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <!-- required -->
            <el-form-item label="SPU编码" prop="spu_code" v-if="ruleForm.type == 1">

                <el-select v-model="ruleForm.spu_code" filterable placeholder="SPU编码" @focus="handleGetSpuList"
                    @change="handleChangeSpu">
                    <el-option v-for="item in spuList" :key="item.spu_code" :label="item.spu_code" :value="item.spu_code">
                        <span style="float: left">{{ item.spu_code }}</span>
                        <span class="goods_name">{{ item.goods_name }}</span>
                    </el-option>
                </el-select>
                <span class="tips">可输入关键字进行搜索</span>
            </el-form-item>
            <el-form-item label="是否启用" required v-if="isEdit">
                <el-switch v-model="ruleForm.state" active-color="#13ce66" inactive-color="#eee" :active-value="1"
                    :inactive-value="0">
                </el-switch>
            </el-form-item>
            <sort @onSortInput="handleSortInput" ref="sort" :ruleForm="{ num: this.sort }" style="width:500px" />
            <el-form-item>
                <el-button type="primary" size="medium" @click="submitForm('ruleForm')"
                    :loading="confirmLoading">保存</el-button>
                <goBack />
            </el-form-item>
        </el-form>
    </div>
</template>
  
<script>
import goBack from '@/components/base/goBack'
import { addBanner, editBanner, getSpu } from '@/api/api.js'
import upLoadImage from '@/components/upload/uploadMore.vue'
import sort from '@/components/base/sort'
import upLoadFiles from '@/components/upload/uploadFiles.vue'
export default {
    components: {
        goBack,
        upLoadImage, sort, upLoadFiles,
    },
    data() {
        return {
            ruleForm: {
                spu_code: ''
            },
            rules: {
                title: [{ required: true, message: '请输入banner名称' }],
                type: [{ required: true, message: '请选择跳转类型' }],
                spu_code: [{ required: true, message: '请选择SPU编码' }],
            },
            spuList: [],
            sort: '',
            isEdit: false,
            sort: '',
            confirmLoading: false,
        }
    },
    created() {
        if (this.$route.query.data) {
            const _data = JSON.parse(decodeURIComponent(this.$route.query.data))
            this.ruleForm = _data
            this.isEdit = true
            console.log('123', _data)
            this.sort = _data.ordering
            this.ruleForm.img = [_data.img]
        } else {
            this.isEdit = false
        }
    },
    methods: {
        handleChangeSpu(val) {
            console.log('val', val)
            this.$set(this.ruleForm, 'spu_code', val)
        },
        handleSortInput(val) {
            this.sort = val
        },
        uploadSuccess(data) {
            console.log('data')
            console.log(data)
            this.ruleForm.img = data
        },
        uploadRemoveImage(data) {
            this.ruleForm.img = data
        },
        uploadVideoStart(video) {
            this.confirmLoading = true
            this.ruleForm.img = video
        },
        uploadFileVideo(video) {
            this.ruleForm.img = video
            setTimeout(() => {
                this.confirmLoading = false
            }, 2000)
        },
        handleIsImg() {
            this.ruleForm.img = []
        },
        handleType() {
            this.ruleForm.spu_code = ''
        },
        handleRemoveVideo(video) {
            this.ruleForm.img = video
        },
        handleGetSpuList() {
            getSpu().then(res => {
                if (res.code === 200) {
                    this.spuList = res.data
                }
            })
        },
        submitForm(ruleForm) {
            this.$refs[ruleForm].validate((valid) => {
                if (valid) {
                    if (this.ruleForm.img.length === 0) {
                        this.$message.error('请上传banner图片/视频')
                        return false
                    } else {
                        const data = {
                            title: this.ruleForm.title,
                            ordering: this.sort,
                            img: this.ruleForm.img[0],
                            type: this.ruleForm.type,
                            spu_code: this.ruleForm.spu_code ? this.ruleForm.spu_code : '',
                            is_img: this.ruleForm.is_img
                        }
                        if (this.isEdit) {
                            data.id = this.ruleForm.id
                            data.state = this.ruleForm.state
                            editBanner(data).then(res => {
                                if (res.code === 200) {
                                    this.$router.push({
                                        name: 'bannerList'
                                    })
                                }
                            })
                        } else {
                            addBanner(data).then(res => {
                                if (res.code === 200) {
                                    this.$router.push({
                                        name: 'bannerList'
                                    })
                                }
                            })
                        }
                    }
                }
            })
        },
    }
}
</script>
  
<style lang="scss" scoped>
.form-item {
    width: 400px;
}

.goods_name {
    float: right;
    color: #8492a6;
    font-size: 13px;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tips {
    margin-left: 10px;
    color: #c5c5c2;
}

.example-img {
    width: 210px;
    position: absolute;
    left: 700px;
    top: 40px;

    div {
        font-size: 16px;
        line-height: 40px;
    }

    img {
        width: 260px;
    }
}

.img {
    width: 210px;
}

.codeOptions {
    width: 400px;
}
</style>