<template>
    <div>
        <el-button type="primary" size="mini" @click="handleToSales" style="margin-bottom: 20px;">批量设置销量基础</el-button>
        <el-table :data="tableData" style="width: 96%" v-loading="loading" border @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
            <el-table-column prop="spu_code" label="商品编码" align="center"></el-table-column>
            <el-table-column prop="goods_type" label="商品类型" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.goods_type">会员商品</div>
                    <div v-if="!scope.row.goods_type">普通商品</div>
                </template>
            </el-table-column>
            <el-table-column prop="base" label="销量基数" align="center"></el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog title="销量基数" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
            <el-form :inline="true" :model="formInline" class="search-form" :rules="rules" ref="formInlineRef">
                <el-form-item label="销量基数" required prop="base">
                    <el-input v-model="formInline.base" show-word-limit maxlength="7" placeholder="请输入销量"
                        type="number"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleEditSalse">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getSalse, editSalse } from '@/api/api'
export default {
    data() {
        return {
            formInline: {},
            tableData: [],
            currentPage: 1,
            totalPage: 1,
            page: 1,
            loading: true,
            username: '',
            dialogVisible: false,
            multipleSelection: [],
            rules: {
                base: [{ required: true, message: '请输入销量基数', trigger: 'blur' }],
            }
        }
    },
    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            getSalse({ p: this.currentPage }).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                }
            })
        },
        handleClose() {
            this.dialogVisible = false
            this.$refs['formInlineRef'].resetFields()
        },
        handleToSales() {
            if (this.multipleSelection.length === 0) {
                this.$message.error('请至少选择一条商品')
            } else {
                this.dialogVisible = true

            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = []
            val.forEach(item => {
                this.multipleSelection.push(item.spu_code)
            });
        },
        handleEditSalse() {
            this.$refs['formInlineRef'].validate((valid) => {
                if (valid) {
                    const data = {
                        id_list: this.multipleSelection,
                        base: this.formInline.base
                    }
                    editSalse(data).then(res => {
                        if (res.code === 200) {
                            this.onList()
                            this.dialogVisible = false
                            this.$refs['formInlineRef'].resetFields()

                        }
                    })
                } else {
                    return false;
                }
            });

        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },

    }
}
</script>
<style lang="scss" scoped>
.pagination-wrapper {
    margin-top: 10px;
}
</style>