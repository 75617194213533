<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="员工名称" prop="name" required>
                <el-input v-model.trim="ruleForm.name" class="form-input" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="登录账号" prop="username" required>
                <el-input v-model.trim="ruleForm.username" class="form-input" maxlength="50" show-word-limit
                    :disabled="isEdit"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" required v-if="!isEdit">
                <el-input type="password" v-model.trim="ruleForm.password" autocomplete="off"
                    class="form-input"></el-input>
            </el-form-item>
            <el-form-item label="是否启用">
                <el-switch v-model="ruleForm.state" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="medium" @click="submitForm('ruleForm')">保存</el-button>
                <goBack />
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import goBack from '@/components/base/goBack'
import { addResource, editResource } from '@/api/api.js'
export default {
    components: {
        goBack
    },
    data() {
        return {
            ruleForm: {},
            isEdit: false,
            rules: {
                name: [{ required: true, message: '请输入员工名称', trigger: 'blur' }],
                username: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            }
        }
    },
    created() {
        if (this.$route.query.data) {
            this.isEdit = true
            this.ruleForm = this.$route.query.data
        }
    },
    mounted() {


    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.isEdit) {
                        const data = {
                            name: this.ruleForm.name,
                            state: this.ruleForm.state,
                            id: this.ruleForm.id
                        }
                        console.log(data)
                        editResource(data).then(res => {
                            if (res.code === 200) {
                                this.$router.push({
                                    name: 'jurisdictionList'
                                })
                            }
                        })
                    } else {
                        const data = {
                            name: this.ruleForm.name,
                            username: this.ruleForm.username,
                            password: window.btoa(this.ruleForm.password),
                        }
                        addResource(data).then(res => {
                            if (res.code === 200) {
                                this.$router.push({
                                    name: 'jurisdictionList'
                                })
                            }
                        })
                    }
                }
            });
        }
    },
}
</script>
<style lang="scss" scoped>
.form-input {
    width: 40%;
}
</style>