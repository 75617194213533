<template>
  <div class="home">
    <div v-if="$route.meta.login">
      <Header />
      <el-container class="content">
        <Menu />
        <el-main>
          <Breadcrumb />
          <div class="wrap">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </div>
    <router-view v-if="!$route.meta.login"></router-view>

  </div>
</template>

<script>
import Menu from "@/components/common/Menu.vue"
import Header from "@/components/common/Header.vue"
import Breadcrumb from '@/components/common/Breadcrumb.vue'
export default {
  components: {
    Header,
    Menu,
    Breadcrumb
  },
  data() {
    return {

    }
  }
}
</script>
<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;

  .content {
    width: 100%;
    position: absolute;
    top: 60px;
    bottom: 0;

    .wrap {
      width: 100%;
      min-height: 80%;
      background-color: #fff;
      margin-top: 20px;
      border-radius: 6px;
      padding: 20px;
      box-sizing: border-box;
    }
  }

  .el-main {
    background-color: rgb(246, 246, 246);
  }
}
</style>