<template>
    <div>
        <div>
            <el-form :model="formLine" :inline="true">
                <el-form-item label="订单编号:">
                    <el-input v-model="formLine.order_sn" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="商品名称:">
                    <el-input v-model="formLine.goods_name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="收货人:">
                    <el-input v-model="formLine.username" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="商品编码:">
                    <el-select placeholder="请选择" v-model="formLine.spu_code" filterable @focus="handleSpuCodeSelect">
                        <el-option v-for="item in spuList" :key="item.spu_code" :label="item.spu_code"
                            :value="item.spu_code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地址市:">
                    <el-cascader v-model="formLine.address" :options="cityOptions"
                        :props="{ label: 'region', value: 'region', children: 'regionEntitys' }"
                        @change="handleChangeAddress"></el-cascader>
                </el-form-item>
                <el-form-item label="付款日期:">
                    <el-date-picker v-model="formLine.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearchClick" size="medium">查询</el-button>
                    <el-button size="medium" @click="handleReasetClick">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="margin-top: 20px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="`全部(${order_count || 0})`" name="0">
                    <orderList :tableData="tableData" @handleShowDialog="handleShowDialog"
                        @handleShowDialogEdit="handleShowDialogEdit" />
                </el-tab-pane>
                <el-tab-pane :label="`待付款(${order_count_1 || 0})`" name="1">
                    <orderList :tableData="tableData" />
                </el-tab-pane>
                <el-tab-pane :label="`待发货(${order_count_2 || 0})`" name="2">
                    <orderList :tableData="tableData" @handleShowDialog="handleShowDialog" />
                </el-tab-pane>
                <el-tab-pane :label="`已发货(${order_count_3 || 0})`" name="3">
                    <orderList :tableData="tableData" @handleShowDialog="handleShowDialog"
                        @handleShowDialogEdit="handleShowDialogEdit" />
                </el-tab-pane>
                <el-tab-pane :label="`已收货(${order_count_5 || 0})`" name="5">
                    <orderList :tableData="tableData" />
                </el-tab-pane>
            </el-tabs>
        </div>
        <div style="margin-top:20px">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog title="输入物流信息" :visible.sync="dialogFormVisible" width="30%" destroy-on-close>
            <el-form :model="dialogForm" inline :rules="dialogRules" ref="dialogForm">
                <el-form-item label="物流公司" required prop="company">
                    <el-input v-model="dialogForm.company" class="location-number" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="物流单号" placeholder="请输入" required prop="number">
                    <el-input v-model="dialogForm.number" class="location-number" style="width:300px"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancelClick">取 消</el-button>
                <el-button type="primary" @click="handleDialogConfirm('dialogForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import orderList from '@/components/base/orderList'
import { getOrder, editOrder, getSpu, sendOrder } from '@/api/api.js'
import { formatTime } from '@/utils/format'
import { cityCodeList } from '@/utils/CityCodes'
export default {
    components: { orderList },
    data() {
        return {
            formLine: {},
            tableData: [],
            spuList: [],
            activeName: 0,
            currentPage: 1,
            totalPage: 1,
            order_count: 0,
            order_count_1: 0,
            order_count_2: 0,
            order_count_3: 0,
            order_count_5: 0,
            orderCompanyLsit: [],
            cityOptions: [],
            cityList: "",
            dialogFormVisible: false,
            dialogForm: {},
            dialogRules: {
                company: [{ required: true, message: '请选择物流公司' }],
                number: [{ required: true, message: '请输入物流单号' }]
            },
            order_sn: '',
            isEditWuliu: false,
        }
    },
    mounted() {
        this.cityOptions = cityCodeList
        this.onList()

    },
    methods: {
        onList() {
            let start_time = null
            let end_time = null
            if (this.formLine.date && this.formLine.date[0]) {
                const date1 = new Date(this.formLine.date[0])
                start_time = formatTime(date1, 'yyyy-MM-dd')
            }

            if (this.formLine.date && this.formLine.date[1]) {
                const date2 = new Date(this.formLine.date[1])
                end_time = formatTime(date2, 'yyyy-MM-dd')
            }
            const data = {
                order_sn: this.formLine.order_sn,
                goods_name: this.formLine.goods_name,
                start_time: start_time,
                end_time: end_time,
                username: this.formLine.username,
                spu_code: this.formLine.spu_code,
                p: this.currentPage,
                city: this.cityList,
                state: this.activeName
            }
            getOrder(data).then(res => {
                if (res.code === 200) {
                    console.log(res)
                    this.order_count = res.order_count
                    this.order_count_1 = res.order_count_1
                    this.order_count_2 = res.order_count_2
                    this.order_count_3 = res.order_count_3
                    this.order_count_5 = res.order_count_5
                    this.tableData = res.data
                    this.totalPage = res.tpage
                }
            })

        },
        handleChangeAddress() {
            this.cityList = this.formLine.address[1]
        },
        handleClick(tab) {
            this.activeName = tab.name
            this.currentPage = 1
            this.onList()

        },
        handleCancelClick() {
            this.dialogFormVisible = false
            this.dialogForm = {}
        },
        handleDialogConfirm() {
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    const data = {
                        order_sn: this.order_sn,
                        wl_corp_name: this.dialogForm.company,
                        wl_corp_sn: this.dialogForm.number
                    }
                    if (this.isEditWuliu) {
                        editOrder(data).then(res => {
                            if (res.code === 200) {
                                this.dialogFormVisible = false
                                this.isEditWuliu = false
                                this.onList()
                                this.dialogForm = {}
                            }
                        })

                    } else {
                        sendOrder(data).then(res => {
                            if (res.code === 200) {
                                this.dialogFormVisible = false
                                this.isEditWuliu = false
                                this.onList()
                                this.dialogForm = {}
                            }
                        })

                    }
                } else {
                    return false;
                }
            });
        },
        handleSpuCodeSelect() {
            if (this.spuList.length <= 0) {
                getSpu().then(res => {
                    if (res.code === 200) {
                        this.spuList = res.data
                    }
                })
            }
        },
        handleShowDialog(goods_sn) {
            this.dialogFormVisible = true
            this.order_sn = goods_sn
        },
        handleShowDialogEdit(data) {
            const _data = {
                company: data.wl_corp_name,
                number: data.wl_corp_sn,
            }
            this.dialogForm = _data
            this.order_sn = data.order_sn
            this.dialogFormVisible = true
            this.isEditWuliu = true
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.onList()
        },
        handleSearchClick() {
            this.currentPage = 1
            this.onList()
        },
        handleReasetClick() {
            this.formLine = {}
            this.cityList = []
            this.currentPage = 1
            this.onList()
        },
    }

}
</script>
<style lang="scss" scoped></style>