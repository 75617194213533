<template>
    <div>
        <el-form :inline="true" :model="formInline" class="search-form">
            <el-form-item label="类型">
                <el-select v-model="formInline.type" placeholder="请选择">
                    <el-option label="收入" value="0"></el-option>
                    <el-option label="支出" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="medium" @click="handleSearchClick" icon="el-icon-search">查询</el-button>
                <el-button size="medium" @click="handleResetClick" icon="el-icon-refresh">重置</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" style="width: 96%" v-loading="loading" border>
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="user_code" label="用户编码" align="center"></el-table-column>
            <el-table-column prop="amount" label="金额" align="center"></el-table-column>
            <el-table-column prop="detail" label="明细" align="center"></el-table-column>
            <el-table-column prop="create_time" label="时间" align="center"></el-table-column>
            <el-table-column prop="type" label="类型" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.type === 0">收入</div>
                    <div v-if="scope.row.type === 1">支出</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import { getComm, } from '@/api/api'
export default {
    data() {

        return {
            formInline: {},
            tableData: [],
            currentPage: 1,
            totalPage: 1,
            loading: true,
            user_code: '',
        }
    },
    created() {
        this.user_code = this.$route.query.user_code
    },
    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            getComm({
                user_code: this.user_code,
                p: this.currentPage,
                type: this.formInline.type
            }).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                }
            })
        },
        handleSearchClick() {
            this.onList()
        },
        handleEditSalse() {
            this.$refs['formInlineRef'].validate((valid) => {
                if (valid) {
                    const data = {
                        id_list: this.multipleSelection,
                        base: this.formInline.base
                    }
                    editSalse(data).then(res => {
                        if (res.code === 200) {
                            this.dialogVisible = false
                            this.onList()
                            this.$refs['formInlineRef'].resetFields()

                        }
                    })
                } else {
                    return false;
                }
            });

        },
        handleResetClick() {
            this.currentPage = 1
            this.formInline = {}
            this.onList()
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },
    }
}
</script>
<style lang="scss" scoped>
.pagination-wrapper {
    margin-top: 10px;
}
</style>