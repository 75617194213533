<template>
    <div class="background">
        <div ref="demo" :style="{ width: '900px', height: '300px' }"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
import { getIndex } from '@/api/api'
export default {
    data() {
        return {
            pvList: [],
        }
    },
    created() {

    },
    mounted() {
        getIndex().then(res => {
            if (res.code == 200) {
                this.pvList = res.data.goods_pv
                const xAxis = []
                const yAxis = []
                this.pvList.forEach(item => {
                    xAxis.push(item.goods_name)
                    yAxis.push(item.pv)
                })
                this.drawCharts(xAxis, yAxis)
            }
        })
    },
    methods: {
        drawCharts(xAxis, yAxis) {
            // 初始化 
            var myChart = echarts.init(this.$refs.demo)
            var option = {
                title: {
                    text: '产品详情访问指数'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: (value) => {
                        let spu = ''
                        let name = ''
                        this.pvList.forEach(item => {
                            if (item.goods_name === value[0].name) {
                                spu = item.spu_code
                            }

                        })
                        if (new String(value[0].name).length > 20) {
                            name = value[0].name.slice(0, 20) + '...'
                        } else {
                            name = value[0].name
                        }
                        const str =
                            ` <div>${name}</div>
                            <div>商品编码：${spu}</div>
                            <div>浏览量：${value[0].value}</div>  `
                        return str
                    }
                },
                legend: {
                    data: ['浏览量'],
                },
                xAxis: {
                    axisLabel: {
                        formatter(value) {
                            if (new String(value).length > 4) {
                                return value.slice(0, 4) + '...'
                            } else {
                                return value
                            }
                        }
                    },

                    data: xAxis
                },
                yAxis: {},
                series: [{
                    // name: '',
                    type: 'line',
                    smooth: true, // 平滑/折线
                    data: yAxis
                }]
            };
            myChart.setOption(option);
        }
    }
}
</script>
<style lang="css" scoped></style>