<template>
    <div class="header">
        <el-header>
            <div class="wrap">
                <img src="@/assets/image/logo.png" alt="">
                <div class="title">养帆起航管理后台</div>
            </div>
            <div class="box">
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                        <span class="name">{{ username }}</span>
                        <i class="el-icon-caret-bottom el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item><i class="el-icon-switch-button"></i> 退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
    </div>
</template>
<script>
import { getToken } from '@/utils/token';
export default {
    data() {
        return {
            username: '',
        }
    },
    created() {
        this.username = getToken('username')
    },
    methods: {
        handleCommand() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.8)'
            });
            setTimeout(() => {
                loading.close()
                this.$router.push({
                    name: 'login'
                })
            }, 2000);
        }
    }
}
</script>
<style scoped lang="scss">
.header {

    .name,
    i {
        color: #fff;
        font-weight: bold;
    }

    .el-header {
        background-color: #2578b5;
        color: #fff;
        line-height: 60px;
        display: flex;
        justify-content: space-between;

        .wrap {
            display: flex;
            align-items: center;

            img {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                margin-right: 10px;
            }

            .title {
                font-size: 20px;
                font-weight: bold;
            }
        }



    }
}
</style>