<template>
    <div>
        <div><el-button type="primary" size="medium" @click="handleToAddPage()" icon="el-icon-plus">新建跑马灯</el-button>
        </div>
        <div style="margin-top:20px">
            <el-table :data="tableData" style="width: 96%" v-loading="loading" border>
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="title" label="内容" align="center"></el-table-column>
                <el-table-column prop="ordering" label="排序" align="center"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                <el-table-column prop="state" label="是否启用" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.state">启用</div>
                        <div v-if="!scope.row.state">不启用</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div style="display:flex">
                            <el-button type="primary" plain size="mini" @click="handleToAddPage(scope.row)">编辑</el-button>
                            <deleteBtn @handleDeleteClick="handleDeleteClick" :id="scope.row.id" :index="scope.$index" />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import { getNotice, delNotice } from '@/api/api'
import deleteBtn from '@/components/base/deleteBtn.vue'
import imageView from '@/components/base/image.vue'
export default {
    components: {
        deleteBtn, imageView
    },
    data() {
        return {
            formInline: {},
            tableData: [],
            currentPage: 1,
            totalPage: 1,
            page: 1,
            loading: true,
            username: ''
        }
    },
    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            const data = {
                p: this.currentPage,
                is_show: this.formInline.is_show,
                title: this.formInline.title
            }
            getNotice(data).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                }
            })
        },
        handleSearchClick() {
            this.onList()
        },
        handleResetClick() {
            this.currentPage = 1
            this.formInline = {}
            this.onList()
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },

        handleToAddPage(data) {
            if (data) {
                const row = {
                    id: data.id,
                    ordering: data.ordering,
                    title: data.title,
                    state: data.state ? 1 : 0
                }
                this.$router.push({
                    name: 'editHorseRaceLamp',
                    query: {
                        data: row
                    }
                })
            } else {
                this.$router.push({
                    name: 'addHorseRaceLamp',
                })
            }
        },
        handleDeleteClick(id, index) {
            const id_list = []
            id_list.push(id)
            delNotice({
                id_list: id_list
            }).then(res => {
                if (res.code === 200) {
                    this.$message.success('删除成功！')
                    this.tableData.splice(index, 1)
                    this.onList()
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.pagination-wrapper {
    margin-top: 10px;
}
</style>