<template>
    <el-button @click="goBack" size="medium" :loading="loading">返回</el-button>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="sass" scoped>

</style>