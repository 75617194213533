<template>
    <div class="header">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="文章名称" prop="title" required>
                <el-input v-model.trim="ruleForm.title" class="form-input" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="title" required>
                <uploadMore :maxLenth="mainLimit" :images="ruleForm.img_url" @uploadMoreSuccess="uploadMainSuccess"
                    @uploadRemoveImage="uploadRemoveMainImage" tips="建议上传尺寸为1500*1500像素" />
            </el-form-item>
            <el-form-item label="文章内容" prop="content" class="editor-wrapper">
                <div>
                    <input id="quill-img" class="quill-img" type="file" style="display:none" />
                    <quill-editor v-model="ruleForm.content" ref="myQuillEditor" :options="editorOprion"
                        class="editor"></quill-editor>
                    <el-upload class="avataruploaderTip" ref="uploadtip" :limit="999" action="#" :show-file-list="false"
                        multiple :http-request="uploadImgTip">
                    </el-upload>
                    <el-dialog title="视频上传（建议上传 mp4 文件，且不超过50Mb）" :visible.sync="videoDialog" :close-on-click-modal="false"
                        class="editerV" width="500px">
                        <div class="editerVideo">
                            <div class="editerVideo_title">
                                <div :class="['editerVideo_title_item', videoTab.tabUpd ? 'editerVideo_title_act' : '']">
                                    上传视频</div>
                            </div>
                            <div class="editerVideo_main">
                                <div>
                                    <div v-if="videoProgress" class="videoProgress">视频上传中，请耐心等待！</div>
                                    <el-upload v-else class="avatar-uploader_video" :limit=10 action="#"
                                        :show-file-list="false" :http-request="uploadVideoTip">
                                        <i class="el-icon-plus avatar-uploader-videoiocn"></i>
                                    </el-upload>
                                </div>
                            </div>
                        </div>
                    </el-dialog>
                </div>
            </el-form-item>
            <el-form-item label="是否展示" v-if="isEdit">
                <el-switch v-model="ruleForm.is_show" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="medium" @click="submitForm('ruleForm')">保存</el-button>
                <goBack />
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import goBack from '@/components/base/goBack.vue'
import uploadMore from '@/components/upload/uploadMore.vue'
import * as qiniu from 'qiniu-js'
import { addArticle, editArticle, upLoad, getQiniuToken } from '@/api/api'
import Video from '@/components/editor/video'
// 详情用的options配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
    ['blockquote', 'code-block'], //引用、代码块儿
    [{ header: 1 }, { header: 2 }], //标题，键值对的形式；1、2表示字体大小
    [{ list: 'ordered' }, { list: 'bullet' }], //列表
    [{ script: 'sub' }, { script: 'super' }], //上下标
    [{ indent: '-1' }, { indent: '+1' }], //缩进
    [{ direction: 'rtl' }], //文本方向
    [{ size: ['small', false, 'large', 'huge'] }], //字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
    [{ color: [] }, { background: [] }], //字体规格名称，字体背景规格名称
    [{ font: [] }], //字体
    [{ align: [] }], //对齐方式
    ['clean'], //清除字体样式
    ['image', 'video'], //上传图片、上传视频(video)、超链接(link)   
];
const Delta = Quill.import('delta')
Quill.register(Video, true)
export default {
    components: {
        quillEditor, goBack, uploadMore,
    },
    data() {
        return {
            ruleForm: {},
            mainLimit: [],
            isEdit: false,
            rules: {
                title: [{ required: true, message: '请输入文章名称' }],
            },
            content: this.details,
            indexVideo: 0,
            videoTab: {
                tabUpd: true
            },
            quill_img: '',
            videoProgress: false, // 视频上传进度
            videoDialog: false,  // 视频上传弹窗
            editorOprion: {
                modules: {
                    toolbar: {
                        container: toolbarOptions,
                        handlers: {
                            video: (val) => {
                                this.onVideo()
                            },
                            image: (value) => {
                                // 覆盖默认的图片上传
                                // 获取光标所在的位置
                                var range = this.$refs.myQuillEditor.quill.getSelection();
                                if (range == null) {
                                    this.indexVideo = 0;
                                } else {
                                    this.indexVideo = range.index;
                                }
                                // 点击隐藏的上传表单
                                if (value) {
                                    document.querySelector('.avataruploaderTip input').click()
                                } else {
                                    this.quill.format('image', false)
                                }
                            }
                        }
                    }
                }
            },

        }
    },
    created() {
        if (this.$route.query.data) {
            const _data = JSON.parse(decodeURIComponent(this.$route.query.data))
            this.isEdit = true
            this.ruleForm = _data
        }
    },
    computed: {
        // 详情编辑器
        editor() {
            return this.$refs.myQuillEditor.quill;
        }
    },
    methods: {
        onVideo() {
            this.videoDialog = true;
            //当编辑器中没有输入文本时，这里获取到的 range 为 null   获取光标位置
            var range = this.$refs.myQuillEditor.quill.getSelection();
            if (range == null) {
                this.indexVideo = 0;
            } else {
                this.indexVideo = range.index;
            }
        },
        //上传视频
        uploadVideoTip(param) {
            getQiniuToken({
                file_name: param.file.name
            }).then(res => {
                if (res.code == 200) {
                    let qiniuToken = res.token
                    let keyName = res.key
                    const putExtra = {}
                    const config = {
                        useCdnDomain: true,
                        region: qiniu.region.z2
                    };
                    this.videoProgress = true
                    let that = this
                    const observable = qiniu.upload(param.file, keyName, qiniuToken, putExtra, config)
                    const observer = {
                        next(res) { },
                        error(err) { },
                        complete(res) {
                            const videoURL = 'https://img.bjyangfanqihang.com/' + res.key
                            // 获取富文本
                            let quill = that.$refs.myQuillEditor.quill
                            // 在光标所在位置 插入视频
                            quill.insertEmbed(that.indexVideo, 'video', videoURL)
                            quill.setSelection(that.indexVideo + 1)
                            that.videoProgress = false
                            that.videoDialog = false;
                        }
                    }
                    const subscription = observable.subscribe(observer) // 上传开始
                }
            })
            // const formData = new FormData()
            // formData.append('image', param.file)
            // this.videoProgress = true;
            // upLoad(formData).then(res => {
            //     res.images.forEach(item => {
            //         const videoURL = `${item}`
            //         // 获取富文本
            //         let quill = this.$refs.myQuillEditor.quill
            //         // 在光标所在位置 插入视频
            //         quill.insertEmbed(this.indexVideo, 'video', videoURL)
            //         quill.setSelection(this.indexVideo + 1)
            //     })
            //     this.videoProgress = false;
            //     this.videoDialog = false;
            // });
            // console.log(this.content, '1234')

        },
        // 图片上传
        uploadImgTip(param) {
            getQiniuToken({
                file_name: param.file.name
            }).then(res => {
                if (res.code == 200) {
                    let qiniuToken = res.token
                    let keyName = res.key
                    const putExtra = {}
                    const config = {
                        useCdnDomain: true,
                        region: qiniu.region.z2
                    };
                    let that = this
                    const observable = qiniu.upload(param.file, keyName, qiniuToken, putExtra, config)
                    const observer = {
                        next(res) { },
                        error(err) { },
                        complete(res) {
                            const url = 'https://img.bjyangfanqihang.com/' + res.key
                            // 获取富文本
                            let quill = that.$refs.myQuillEditor.quill
                            // 在光标所在位置 插入视频
                            quill.insertEmbed(that.indexVideo, 'image', url)
                            quill.setSelection(that.indexVideo + 1)
                        }
                    }
                    const subscription = observable.subscribe(observer) // 上传开始
                }
            })
            // const formData = new FormData()
            // formData.append('image', param.file)
            // upLoad(formData).then(res => {
            //     if (res.images && res.images.length > 0) {
            //         res.images.forEach(item => {
            //             let url = `${item}`
            //             let quill = this.$refs.myQuillEditor.quill
            //             // 插入图片链接
            //             quill.insertEmbed(this.indexVideo, 'image', url)
            //             quill.setSelection(this.indexVideo + 1)
            //         })
            //     }
            // });
            // console.log(this.$refs.myQuillEditor.quill, '123')
        },
        uploadMainSuccess(data) {
            if (this.ruleForm.img_url && this.ruleForm.img_url.length > 0) {
                const a = this.ruleForm.img_url
                const b = a.concat(data)
                let c = new Set(b)
                c = [...c]
                this.ruleForm.img_url = c
            } else {
                this.ruleForm.img_url = data
            }
        },
        uploadRemoveMainImage(data) {
            this.ruleForm.img_url = data
        },
        submitForm(ruleForm) {
            this.$refs[ruleForm].validate((valid) => {
                if (valid) {
                    if (this.ruleForm.img_url.length === 0) {
                        this.$message.error('请上传文章图片')
                        return false
                    } else {
                        const data = {
                            title: this.ruleForm.title,
                            img_url: this.ruleForm.img_url[0],
                            content: this.ruleForm.content
                        }
                        if (this.isEdit) {
                            data.id = this.ruleForm.id
                            data.is_show = this.ruleForm.is_show
                            editArticle(data).then(res => {
                                if (res.code === 200) {
                                    this.$router.push({
                                        name: 'newsList'
                                    })
                                }
                            })
                        } else {
                            addArticle(data).then(res => {
                                if (res.code === 200) {
                                    this.$router.push({
                                        name: 'newsList'
                                    })
                                }
                            })
                        }
                    }
                }
            })
        },
    }
}
</script>
<style scoped lang="scss">
@import url('@/assets/style/editor.css');

.form-input {
    width: 40%;
}

.editor-wrapper {
    width: 800px;
    height: 360px;
    margin-bottom: 60px;
}

.editor {
    width: 800px;
    height: 300px;
}

/deep/.ql-snow .ql-editor img {
    margin-top: -5px;
}
</style>