<template>
    <div class="menu-wrapper">
        <el-menu :default-active="routerpath" router class="el-menu-vertical-demo" unique-opened background-color="#2578b5"
            text-color="#fff" active-text-color="#cfd527">
            <template v-for="item in navListDefault">
                <!--  没有二级 -->
                <el-menu-item v-if="item.children && item.children.length === 0" :key="item.id" :data="item"
                    :index="item.name" class="sub-menu">
                    <i :class="item.icon"></i>
                    <span slot="title">{{ item.navItem }}</span>
                </el-menu-item>
                <!-- 有二级 -->
                <el-submenu v-else :index="item.name" :data="item" :key="item.index" class="sub-menu">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.navItem }}</span>
                    </template>
                    <el-menu-item v-for="subItem in item.children" :key="subItem.id" :index="subItem.name"
                        style="padding-left:50px">
                        {{ subItem.navItem }}
                    </el-menu-item>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
export default {
    data() {
        return {
            navListDefault: [
                {
                    name: '/index',
                    navItem: '首页',
                    icon: 'el-icon-s-data',
                    id: 0,
                    children: []
                },
                {
                    name: '/commodityManagement',
                    navItem: '商品管理',
                    icon: 'el-icon-s-goods',
                    id: 1,
                    children: [
                        { name: '/commodity/productList', navItem: '商品列表', id: 2 },
                    ]
                },
                {
                    name: '/orderManagement',
                    navItem: '订单管理',
                    icon: 'el-icon-s-order',
                    id: 19,
                    children: [
                        { name: '/order/orderList', navItem: '订单列表', id: 20 },
                    ]
                },
                {
                    name: '/layoutManagement',
                    navItem: '布局管理',
                    icon: 'el-icon-s-grid',
                    id: 3,
                    children: [
                        { name: '/layout/bannerList', navItem: 'banner列表', id: 4 },
                        { name: '/layout/horseRaceLampList', navItem: '跑马灯列表', id: 5 },
                    ]
                },
                {
                    name: '/marketingManagemnt',
                    navItem: '营销中心',
                    icon: 'el-icon-s-marketing',
                    id: 6,
                    children: [
                        { name: '/marketing/sourceMaterialList', navItem: '素材库管理', id: 7 },
                        { name: '/marketing/commentList', navItem: '评论列表', id: 8 },
                        { name: '/marketing/salesVolumeList', navItem: '销量列表', id: 9 },
                    ]
                },
                {
                    name: '/newsManagement',
                    navItem: '学院管理',
                    icon: 'el-icon-s-shop',
                    id: 10,
                    children: [
                        { name: '/news/newsList', navItem: '文章列表', id: 11 },
                    ]
                },
                {
                    name: '/afterSalesManagement',
                    navItem: '售后管理',
                    icon: 'el-icon-s-platform',
                    id: 17,
                    children: [
                        { name: '/afterSales/returnList', navItem: '售后订单', id: 22 },
                        { name: '/afterSales/addressList', navItem: '售后地址', id: 18 },
                    ]
                },
                {
                    name: '/userManagement',
                    navItem: '用户管理',
                    icon: 'el-icon-user-solid',
                    id: 15,
                    children: [
                        { name: '/user/userList', navItem: '用户列表', id: 16 },
                        // { name: '/user/couponList', navItem: '消费券列表', id: 21 },
                    ]
                },
                {
                    name: '/jurisdictionManagement',
                    navItem: '权限管理',
                    icon: 'el-icon-s-tools',
                    id: 12,
                    children: [
                        { name: '/jurisdiction/jurisdictionList', navItem: '管理员列表', id: 13 },
                        { name: '/jurisdiction/password', navItem: '修改密码', id: 14 },
                    ]
                },
            ]
        };
    },
    created() {

    },
    mounted() {
    },
    computed: {
        routerpath() {
            return this.$route.meta.GuidePath ? this.$route.meta.JumpPath : this.$route.path
        }
    },
}
</script>

<style lang="scss" scoped>
/deep/ .el-menu {
    border: none
}

.menu-wrapper {
    overflow-y: auto;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 100%;
}

.title {
    height: 60px;
    line-height: 60px;
    background: #094ca2;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    color: #fff;
    vertical-align: middle;

    img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
        // border-radius: 50%;
    }
}

.sub-menu {
    i {
        color: #fff;
    }
}
</style>