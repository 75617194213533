<template>
    <div>
        <div>
            <el-form :inline="true" :model="formInline" class="search-form">
                <el-form-item label="昵称">
                    <el-input v-model="formInline.nickname" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="真实姓名">
                    <el-input v-model="formInline.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="是否是会员">
                    <el-select v-model="formInline.is_vip" placeholder="请选择">
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="handleSearchClick" icon="el-icon-search">查询</el-button>
                    <el-button size="medium" @click="handleResetClick" icon="el-icon-refresh">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="margin-top:20px">
            <el-table :data="tableData" style="width: 96%" v-loading="loading" border>
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="nickName" label="昵称" align="center"></el-table-column>
                <el-table-column prop="invite_user_info.nickname" label="上级" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.invite_user_info.nickname">
                            <div>{{ scope.row.invite_user_info.nickname }}</div>
                            <div>{{ scope.row.invite_user_info.user_code }}</div>
                        </div>
                        <div v-else>暂无上级</div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="真实姓名" align="center"></el-table-column>
                <el-table-column prop="phone" label="手机号" align="center"></el-table-column>
                <el-table-column prop="useful_life" label="会员有效期" align="center"></el-table-column>
                <el-table-column prop="invite_count" label="邀请人数" align="center"></el-table-column>
                <el-table-column prop="user_code" label="用户编码" align="center"></el-table-column>
                <el-table-column prop="balance" label="可用消费券" align="center"></el-table-column>
                <el-table-column prop="login_datetime" label="上次登录时间" align="center"></el-table-column>
                <el-table-column prop="is_vip" label="是否会员" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_vip">是</div>
                        <div v-if="!scope.row.is_vip">否</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="primary" plain size="mini" @click="handleToDetail(scope.row)">查看</el-button>
                            <el-button type="warning" plain size="mini" @click="handleEditCoupon(scope.row)">编辑</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog title="修改用户消费券余额" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
            <el-form :inline="true" :model="ruleForm" class="search-form" :rules="rules" ref="formInlineRef">
                <el-form-item label="扣除金额:" required prop="amount">
                    <el-input v-model="ruleForm.amount" show-word-limit maxlength="7" placeholder="请输入"></el-input>
                    <div class="tips">注:@用户消费券余额-扣除金额=修改后代金券余额;@系统扣除会产生扣除日志供双方查看</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleEditSalse">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getUser, editComm } from '@/api/api'
export default {
    data() {
        const price = (rule, value, callback) => {
            if (value == '' || value == undefined) {
                callback(new Error(' 请输入扣除金额!'));
            } else if (/^\d+(\.\d{1,2})?$/.test(value) == false) {
                callback(new Error("请输入数字,且最多保留小数点后两位"));
            } else if (Number(value) < 0) {
                callback(new Error('扣除金额不能小于0'));
            } else if (value.length > 10) {
                callback(new Error("长度不能大于10个字符"));
            } else {
                callback();
            }
        };
        return {
            formInline: {},
            tableData: [],
            currentPage: 1,
            totalPage: 1,
            loading: true,
            dialogVisible: false,
            user_code: '',
            rules: {
                amount: [{ validator: price }],
            },
            ruleForm: {}
        }
    },
    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            const data = this.formInline
            data.p = this.currentPage
            getUser(data).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                    this.totalPage = res.tpage
                }
            })
        },
        handleToDetail(row) {
            this.$router.push({
                name: 'couponList',
                query: {
                    user_code: row.user_code
                }
            })
        },
        handleEditCoupon(row) {
            this.dialogVisible = true
            this.user_code = row.user_code
        },
        handleClose() {
            this.dialogVisible = false
            this.$refs['formInlineRef'].resetFields()
        },
        handleEditSalse() {
            this.$refs['formInlineRef'].validate((valid) => {
                if (valid) {
                    editComm({
                        user_code: this.user_code,
                        amount: this.ruleForm.amount
                    }).then(res => {
                        if (res.code === 200) {
                            this.dialogVisible = false
                            this.onList()
                            this.$refs['formInlineRef'].resetFields()

                        }
                    })
                } else {
                    return false;
                }
            });

        },
        handleSearchClick() {
            this.onList()
        },
        handleResetClick() {
            this.currentPage = 1
            this.formInline = {}
            this.onList()
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },
    }
}
</script>
<style lang="scss" scoped>
.pagination-wrapper {
    margin-top: 10px;
}

.tips {
    color: rgb(217, 27, 27);
}
</style>