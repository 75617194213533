import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}



import login from '@/views/login.vue';
import index from '@/views/index.vue';
// 商品管理
import productList from '@/views/commodityManagement/productList.vue';
import addProduct from '@/views/commodityManagement/addProduct.vue'
// 布局管理
import bannerList from '@/views/layoutManagement/bannerList.vue';
import addBanner from '@/views/layoutManagement/addBanner.vue';
import horseRaceLampList from '@/views/layoutManagement/horseRaceLampList.vue';
import addHorseRaceLamp from '@/views/layoutManagement/addHorseRaceLamp.vue';
// 文章管理
import newsList from '@/views/newsManagement/newsList.vue';
import addNews from '@/views/newsManagement/addNews.vue'
// 营销中心
import commentList from '@/views/marketingManagemnt/commentList.vue'
import addComment from '@/views/marketingManagemnt/addComment.vue'
import salesVolumeList from '@/views/marketingManagemnt/salesVolumeList.vue';
import sourceMaterialList from '@/views/marketingManagemnt/sourceMaterialList.vue';
// 权限管理
import jurisdictionList from '@/views/jurisdictionManagement/jurisdictionList.vue';
import addJurisdiction from '@/views/jurisdictionManagement/addJurisdiction.vue'
import password from '@/views/jurisdictionManagement/password.vue'
// 用户管理
import userList from '@/views/userManagement/list.vue'
import couponList from '@/views/userManagement/coupon.vue'
// 售后管理 
import addressList from '@/views/afterSalesManagement/index.vue'
import returnList from '@/views/afterSalesManagement/list.vue'
// 订单管理
import orderList from '@/views/orderManagement/index.vue'
Vue.use(Router)


export default new Router({
  mode: 'hash',
  routes: [
    { path: '*', redirect: '/login' },
    { path: '/login', name: 'login', component: login },
    { path: '/index', name: 'index', component: index, meta: { login: true, ChineseName: '首页' } },

    // 商品管理
    { path: '/commodity/productList', name: 'productList', component: productList, meta: { login: true, ChineseName: '商品列表', id: 2 } },
    { path: '/commodity/addProduct', name: 'addProduct', component: addProduct, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '新建商品', JumpPath: '/commodity/addProduct' } },
    { path: '/commodity/addProduct', name: 'editProduct', component: addProduct, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '编辑商品', JumpPath: '/commodity/addProduct' } },

    // 商品布局管理
    { path: '/layout/bannerList', name: 'bannerList', component: bannerList, meta: { login: true, ChineseName: 'banner', id: 4 } },
    { path: '/layout/addBanner', name: 'addBanner', component: addBanner, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '新建banner', JumpPath: '/layout/bannerList' } },
    { path: '/layout/addBanner', name: 'editBanner', component: addBanner, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '编辑banner', JumpPath: '/layout/bannerList' } },
    { path: '/layout/horseRaceLampList', name: 'horseRaceLampList', component: horseRaceLampList, meta: { login: true, ChineseName: '跑马灯列表', id: 5 } },
    { path: '/layout/addHorseRaceLamp', name: 'addHorseRaceLamp', component: addHorseRaceLamp, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '新建跑马灯', JumpPath: '/layout/addHorseRaceLamp' } },
    { path: '/layout/addHorseRaceLamp', name: 'editHorseRaceLamp', component: addHorseRaceLamp, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '编辑跑马灯', JumpPath: '/layout/addHorseRaceLamp' } },
    // 文章管理
    { path: '/news/newsList', name: 'newsList', component: newsList, meta: { login: true, ChineseName: '文章列表', id: 11 } },
    { path: '/news/addNews', name: 'addNews', component: addNews, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '新建文章', JumpPath: '/news/addNews' } },
    { path: '/news/addNews', name: 'editNews', component: addNews, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '编辑文章', JumpPath: '/news/addNews' } },
    // 营销中心
    { path: '/marketing/commentList', name: 'commentList', component: commentList, meta: { login: true, ChineseName: '评论列表', id: 8 } },
    { path: '/marketing/addComment', name: 'addComment', component: addComment, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '新建评论', JumpPath: '/marketing/addComment' } },
    { path: '/marketing/addComment', name: 'editComment', component: addComment, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '编辑评论', JumpPath: '/marketing/addComment' } },
    { path: '/marketing/salesVolumeList', name: 'salesVolumeList', component: salesVolumeList, meta: { login: true, ChineseName: '销量列表', id: 9 } },
    { path: '/marketing/sourceMaterialList', name: 'sourceMaterialList', component: sourceMaterialList, meta: { login: true, ChineseName: '素材库管理', id: 7 } },
    // 权限管理
    { path: '/jurisdiction/jurisdictionList', name: 'jurisdictionList', component: jurisdictionList, meta: { login: true, ChineseName: '管理员列表', id: 13 } },
    { path: '/jurisdiction/addJurisdiction', name: 'addJurisdiction', component: addJurisdiction, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '新建管理员', JumpPath: '/jurisdiction/addJurisdiction' } },
    { path: '/jurisdiction/addJurisdiction', name: 'editJurisdiction', component: addJurisdiction, meta: { login: true, breadNumber: 2, GuidePath: true, ChineseName: '编辑管理员', JumpPath: '/jurisdiction/addJurisdiction' } },
    { path: '/jurisdiction/password', name: 'password', component: password, meta: { login: true, ChineseName: '密码管理', id: 14 } },
    // 用户管理 
    { path: '/user/userList', name: 'userList', component: userList, meta: { login: true, ChineseName: '用户列表', id: 16 } },
    { path: '/user/couponList', name: 'couponList', component: couponList, meta: { login: true, ChineseName: '消费券列表', id: 21 } },
    // 售后管理 
    { path: '/afterSales/addressList', name: 'addressList', component: addressList, meta: { login: true, ChineseName: '退货地址', id: 18 } },
    { path: '/afterSales/returnList', name: 'returnList', component: returnList, meta: { login: true, ChineseName: '退货列表', id: 22 } },
    // 订单那管理
    { path: '/order/orderList', name: 'orderList', component: orderList, meta: { login: true, ChineseName: '订单列表', id: 20 } },
  ]

})