<template>
    <div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="原密码" prop="old_password" required>
                <el-input type="password" v-model.trim="ruleForm.old_password" class="form-input"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="new_password" required>
                <el-input type="password" v-model.trim="ruleForm.new_password" autocomplete="off"
                    class="form-input"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass" required>
                <el-input type="password" v-model.trim="ruleForm.checkPass" autocomplete="off"
                    class="form-input"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="medium" @click="submitForm('ruleForm')">确认修改</el-button>
                <goBack />
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import goBack from '@/components/base/goBack'
import { changePassword } from '@/api/api.js'
import { getToken } from '@/utils/token';
export default {
    components: {
        goBack
    },
    data() {
        var checkOldPass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else if (value.length < 6) {
                callback(new Error('密码长度不能小于6位'));
            } else if (value.length > 20) {
                callback(new Error('密码长度不能大于20位'));
            } else {
                callback();
            }
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else if (/^[0-9a-zA-Z]*$/g.test(value) == false) {
                callback(new Error('密码只能输入字母、数字和特殊符号'));
            } else if (value.length < 6) {
                callback(new Error('密码长度不能小于6位'));
            } else if (value.length > 20) {
                callback(new Error('密码长度不能大于20位'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.new_password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };

        return {
            ruleForm: {
                old_password: '',
                new_password: '',
                checkPass: ''
            },
            rules: {
                old_password: [
                    { validator: checkOldPass, trigger: 'blur' }
                ],
                new_password: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    changePassword({
                        old_password: window.btoa(this.ruleForm.old_password),
                        new_password: window.btoa(this.ruleForm.new_password),
                    }).then(res => {
                        if (res.code === 200) {
                            this.$message.success('密码修改成功,即将跳转至登录页，请重新登录')
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'login'
                                })
                            }, 2000)

                        }
                    })
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.form-input {
    width: 40%;
}
</style>