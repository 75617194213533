<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px">
            <el-form-item label="商品名称" prop="goods_name" required>
                <el-input v-model.trim="ruleForm.goods_name" class="form-item" placeholder="请输入" maxlength="100"
                    show-word-limit id="goods_name"></el-input>
            </el-form-item>
            <el-form-item label="商品类型" prop="goods_type" required>
                <el-select v-model="ruleForm.goods_type" placeholder="请选择" @change="handleChange">
                    <el-option label="会员商品" :value="0"></el-option>
                    <el-option label="普通商品" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="详情" prop="details" class="editor-wrapper">
                <div>
                    <input id="quill-img" class="quill-img" type="file" style="display:none" />
                    <quill-editor v-model="ruleForm.details" ref="myQuillEditor" :options="editorOprion"
                        class="editor"></quill-editor>
                    <el-upload class="avataruploaderTip" ref="uploadtip" :limit="999" action="#" :show-file-list="false"
                        multiple :http-request="uploadImgTip">
                    </el-upload>
                    <el-dialog title="视频上传（建议上传 mp4 文件，且不超过50Mb）" :visible.sync="videoDialog" :close-on-click-modal="false"
                        class="editerV" width="500px">
                        <div class="editerVideo">
                            <div class="editerVideo_title">
                                <div :class="['editerVideo_title_item', videoTab.tabUpd ? 'editerVideo_title_act' : '']">
                                    上传视频</div>
                            </div>
                            <div class="editerVideo_main">
                                <div>
                                    <div v-if="videoProgress" class="videoProgress">视频上传中，请耐心等待！</div>
                                    <el-upload v-else class="avatar-uploader_video" :limit=10 action="#"
                                        :show-file-list="false" :http-request="uploadVideoTip">
                                        <i class="el-icon-plus avatar-uploader-videoiocn"></i>
                                    </el-upload>
                                </div>
                            </div>
                        </div>
                    </el-dialog>
                </div>
            </el-form-item>
            <el-form-item label="商品主图" required>
                <uploadMore :maxlength="10" :images="ruleForm.img_url" @uploadMoreSuccess="uploadMainSuccess"
                    @uploadRemoveImage="uploadRemoveMainImage" tips="建议上传尺寸为1500*1500像素" />
            </el-form-item>
            <el-form-item label="商品视频" prop="video">
                <upLoadFiles :limit="1" :images="video_file" accept=".mp4" @uploadFileStart="uploadVideoStart"
                    @uploadFile="uploadFileVideo" @handleRemove="handleRemoveVideo" id="video_url" />
            </el-form-item>
            <el-form-item label="商品货号" required>
                <el-table :data="spuData" border style="width: 100%">
                    <el-table-column prop="sku_name" label="规格名称" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sku_name">{{ scope.row.sku_name }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="img_url" label="商品图片" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.img_url">
                                <imgView :src="scope.row.img_url" style="width:80px" />
                            </div>
                            <div v-else>/</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="单价" align="center"></el-table-column>
                    <el-table-column prop="rebate_money" label="佣金" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="medium"
                                @click="handleEditClick(scope.row, scope.$index)">编辑</el-button>
                            <el-button type="text" size="medium" @click="handleDelClick(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-button type="primary" size="small" @click="handleEditClick()">新增规格</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')" :loading="confirmLoading">保存</el-button>
                <goBack />
            </el-form-item>
        </el-form>
        <!-- 添加货号弹窗 -->
        <el-dialog title="规格数据" v-if="dialogFormVisible" :visible.sync="dialogFormVisible" destroy-on-close
            :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <el-form :model="goodsStyleForm" :rules="dialogRules" ref="dialogForm" label-width="160px">
                <el-form-item label="规格名称" required prop="sku_name">
                    <el-input v-model.trim="goodsStyleForm.sku_name" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="单价" required prop="price">
                    <el-input v-model.trim="goodsStyleForm.price" maxlength="10" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="佣金" required v-if="ruleForm.goods_type === 0">
                    <el-input v-model="goodsStyleForm.rebate_money" maxlength="10" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="规格图片" required>
                    <uploadMore v-if="goodsStyleForm.img_url && goodsStyleForm.img_url.length > 0"
                        :images="[goodsStyleForm.img_url]" @uploadMoreSuccess="uploadFileSpu"
                        @uploadRemoveImage="handleRemoveSpu" tips="建议上传尺寸为1500*1500像素" />
                    <uploadMore v-else @uploadMoreSuccess="uploadFileSpu" @uploadRemoveImage="handleRemoveSpu"
                        tips="建议上传尺寸为1500*1500像素" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancelDialog" :loading="dialogConfirmLoading"
                    id="handleCancelDialog">取消</el-button>
                <el-button type="primary" :loading="dialogConfirmLoading" @click="submitDialogForm()"
                    id="submitDialogForm">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import upLoadFiles from '@/components/upload/uploadFiles.vue'
import uploadMore from '@/components/upload/uploadMore.vue'
import { editGoods, addGoods, upLoad, getQiniuToken } from '@/api/api'
import imgView from '@/components/base/image'
import goBack from '@/components/base/goBack.vue'
import * as qiniu from 'qiniu-js'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor, Quill } from 'vue-quill-editor'
import Video from '@/components/editor/video'
// 详情用的options配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
    ['blockquote', 'code-block'], //引用、代码块儿
    [{ header: 1 }, { header: 2 }], //标题，键值对的形式；1、2表示字体大小
    [{ list: 'ordered' }, { list: 'bullet' }], //列表
    [{ script: 'sub' }, { script: 'super' }], //上下标
    [{ indent: '-1' }, { indent: '+1' }], //缩进
    [{ direction: 'rtl' }], //文本方向
    [{ size: ['small', false, 'large', 'huge'] }], //字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
    [{ color: [] }, { background: [] }], //字体规格名称，字体背景规格名称
    [{ font: [] }], //字体
    [{ align: [] }], //对齐方式
    ['clean'], //清除字体样式
    ['image', 'video'], //上传图片、上传视频(video)、超链接(link)   
];
const Delta = Quill.import('delta')
Quill.register(Video, true)

export default {
    components: {
        imgView, upLoadFiles, uploadMore, goBack, quillEditor
    },
    data() {
        const price = (rule, value, callback) => {
            if (value == '' || value == undefined) {
                callback(new Error('请输入单价!'));
            } else if (/^\d+(\.\d{1,2})?$/.test(value) == false) {
                callback(new Error("请输入数字,且最多保留小数点后两位"));
            } else if (Number(value) <= 0) {
                callback(new Error('单价不能小于0'));
            } else if (value.length > 10) {
                callback(new Error("长度不能大于10个字符"));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {},
            mainImageList: [],
            goodsStyleForm: {
                imageList: []
            },
            dialogFormVisible: false,
            rules: {
                goods_name: [{ required: true, message: '请输入商品名称' }],
                goods_type: [{ required: true, message: '请选择商品类型' }],
                // img_url: [{ required: true, message: '请上传商品主图' }],
            },
            dialogRules: {
                sku_name: [{ required: true, message: '请输入规格名称' }],
                img_url: [{ message: '请上传商品图片' }],
                price: [{ validator: price }],
            },
            spuData: [],
            qiniu_image_url: '',
            disabledBtn: false,
            editIndex: '',
            dialogConfirmLoading: false,
            formDate: '',
            video_file: [],
            confirmLoading: false,
            isEdit: false,
            delData: '',
            isEditPage: false,

            indexVideo: 0,
            videoTab: {
                tabUpd: true
            },
            quill_img: '',
            videoProgress: false, // 视频上传进度
            videoDialog: false,  // 视频上传弹窗
            editorOprion: {
                modules: {
                    toolbar: {
                        container: toolbarOptions,
                        handlers: {
                            video: (val) => {
                                this.onVideo()
                            },
                            image: (value) => {
                                // 覆盖默认的图片上传
                                // 获取光标所在的位置
                                var range = this.$refs.myQuillEditor.quill.getSelection();
                                if (range == null) {
                                    this.indexVideo = 0;
                                } else {
                                    this.indexVideo = range.index;
                                }
                                // 点击隐藏的上传表单
                                if (value) {
                                    document.querySelector('.avataruploaderTip input').click()
                                } else {
                                    this.quill.format('image', false)
                                }
                            }
                        }
                    }
                }
            },
        }
    },
    created() {
        // 编辑
        if (this.$route.query.data) {
            this.isEditPage = true
            this.ruleForm = JSON.parse(decodeURIComponent(this.$route.query.data))
            console.log(this.ruleForm, 'this.ruleForm')
            this.spuData = this.ruleForm.goods_style
            // 视频展示
            this.video_file = []
            if (this.ruleForm.video_url) {
                this.video_file.push(this.ruleForm.video_url)
            }
            this.ruleForm.video_url = this.ruleForm.video_url || ''
            this.ruleForm.details = this.ruleForm.detail
        } else {
            this.isEditPage = false
        }
    },
    computed: {
        // 详情编辑器
        editor() {
            return this.$refs.myQuillEditor.quill;
        }
    },
    methods: {
        handleChange(type) {
            console.log(type)
            this.ruleForm.type = type
        },
        onVideo() {
            this.videoDialog = true;
            //当编辑器中没有输入文本时，这里获取到的 range 为 null   获取光标位置
            var range = this.$refs.myQuillEditor.quill.getSelection();
            if (range == null) {
                this.indexVideo = 0;
            } else {
                this.indexVideo = range.index;
            }
        },
        //上传视频
        uploadVideoTip(param) {
            getQiniuToken({
                file_name: param.file.name
            }).then(res => {
                if (res.code == 200) {
                    let qiniuToken = res.token
                    let keyName = res.key
                    const putExtra = {}
                    const config = {
                        useCdnDomain: true,
                        region: qiniu.region.z2
                    };
                    this.videoProgress = true
                    let that = this
                    const observable = qiniu.upload(param.file, keyName, qiniuToken, putExtra, config)
                    const observer = {
                        next(res) { },
                        error(err) { },
                        complete(res) {
                            const videoURL = 'https://img.bjyangfanqihang.com/' + res.key
                            // 获取富文本
                            let quill = that.$refs.myQuillEditor.quill
                            // 在光标所在位置 插入视频
                            quill.insertEmbed(that.indexVideo, 'video', videoURL)
                            quill.setSelection(that.indexVideo + 1)
                            that.videoProgress = false
                            that.videoDialog = false;
                        }
                    }
                    const subscription = observable.subscribe(observer) // 上传开始
                }
            })
            // const formData = new FormData()
            // formData.append('image', param.file)
            // this.videoProgress = true;
            // upLoad(formData).then(res => {
            //     res.images.forEach(item => {
            //         const videoURL = `${item}`
            //         // 获取富文本
            //         let quill = this.$refs.myQuillEditor.quill
            //         // 在光标所在位置 插入视频
            //         quill.insertEmbed(this.indexVideo, 'video', videoURL)
            //         quill.setSelection(this.indexVideo + 1)
            //     })
            //     this.videoProgress = false;
            //     this.videoDialog = false;
            // });

        },
        // 图片上传
        uploadImgTip(param) {
            getQiniuToken({
                file_name: param.file.name
            }).then(res => {
                if (res.code == 200) {
                    let qiniuToken = res.token
                    let keyName = res.key
                    const putExtra = {}
                    const config = {
                        useCdnDomain: true,
                        region: qiniu.region.z2
                    };
                    let that = this
                    const observable = qiniu.upload(param.file, keyName, qiniuToken, putExtra, config)
                    const observer = {
                        next(res) { },
                        error(err) { },
                        complete(res) {
                            const url = 'https://img.bjyangfanqihang.com/' + res.key
                            // 获取富文本
                            let quill = that.$refs.myQuillEditor.quill
                            // 在光标所在位置 插入视频
                            quill.insertEmbed(that.indexVideo, 'image', url)
                            quill.setSelection(that.indexVideo + 1)
                        }
                    }
                    const subscription = observable.subscribe(observer) // 上传开始
                }
            })
            // const formData = new FormData()
            // formData.append('image', param.file)
            // upLoad(formData).then(res => {
            //     if (res.images && res.images.length > 0) {
            //         res.images.forEach(item => {
            //             let url = `${item}`
            //             let quill = this.$refs.myQuillEditor.quill
            //             // 插入图片链接
            //             quill.insertEmbed(this.indexVideo, 'image', url)
            //             quill.setSelection(this.indexVideo + 1)
            //         })
            //     }
            // });
        },
        uploadMainSuccess(data) {
            if (this.ruleForm.img_url && this.ruleForm.img_url.length > 0) {
                const a = this.ruleForm.img_url
                const b = a.concat(data)
                let c = new Set(b)
                c = [...c]
                this.ruleForm.img_url = c
            } else {
                this.ruleForm.img_url = data
            }

        },
        uploadRemoveMainImage(data) {
            this.ruleForm.img_url = data
        },
        handleCancelDialog() {
            this.dialogFormVisible = false
            this.$refs['dialogForm'].resetFields()
            if (this.editIndex > 0) {
                this.spuData[this.editIndex].qiniu_image_url = this.delData
            }
        },
        uploadVideoStart() {
            console.log('开始上传视频')
            this.confirmLoading = true
        },
        handleDelClick(index) {
            this.spuData.splice(index, 1)
        },
        handleEditClick(data, index) {
            if (data) {
                this.dialogFormVisible = true
                this.goodsStyleForm = data
                this.delData = data.qiniu_image_url
                this.editIndex = index
            } else {
                this.dialogFormVisible = true
                this.goodsStyleForm = {}
                this.editIndex = -1
            }

        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.spuData.length <= 0) {
                        this.$message.error('请至少添加一条商品货号信息！')
                        return false
                    }
                    if (this.ruleForm.details && this.ruleForm.details.length > 2000) {
                        this.$message.error('商品详情的长度不能超过2000个字符')
                        return false
                    }
                    if (this.ruleForm.img_url.length === 0) {
                        this.$message.error('请上传商品主图')
                        return false
                    }
                    const formData = {
                        goods_name: this.ruleForm.goods_name,
                        details: this.ruleForm.details ? this.ruleForm.details : '',
                        img_url: this.ruleForm.img_url,
                        video_url: this.ruleForm.video_url ? this.ruleForm.video_url + '' : '',
                        goods_type: this.ruleForm.goods_type,
                        goods_style: this.spuData
                    }
                    if (this.isEditPage) {
                        formData.spu_code = this.ruleForm.spu_code
                        editGoods(formData).then(res => {
                            if (res.code === 200) {
                                this.$router.push({
                                    name: 'productList'
                                })
                            }
                        })
                    } else {
                        addGoods(formData).then(res => {
                            if (res.code === 200) {
                                this.$router.push({
                                    name: 'productList'
                                })
                            }
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        submitDialogForm() {
            if (this.goodsStyleForm.img_url == '' || this.goodsStyleForm.img_url == undefined) {
                this.$message.error('请上传商品图片！')
                return false
            }
            if (Number(this.goodsStyleForm.rebate_money) >= Number(this.goodsStyleForm.price)) {
                this.$message.error('佣金不能高于单价')
                return false
            }
            if (Number(this.goodsStyleForm.rebate_money) < 0) {
                this.$message.error('佣金不能小于0')
                return false
            }
            if (this.goodsStyleForm.rebate_money && (/^\d+(\.\d{1,2})?$/.test(this.goodsStyleForm.rebate_money) == false)) {
                this.$message.error('佣金请输入数字,且最多保留小数点后两位')
                return false
            }
            if (this.ruleForm.type === 0 && this.goodsStyleForm.rebate_money === '') {
                this.$message.error('会员商品，请填写佣金')
                return false
            }
            this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    if (this.editIndex == -1) {
                        this.dialogFormVisible = false
                        this.spuData.push(this.goodsStyleForm)

                    } else {
                        this.dialogFormVisible = false
                    }
                } else {
                    return false;
                }
            });

        },
        uploadFileVideo(name) {
            console.log('name', name,)
            this.ruleForm.video_url = name[0]
            setTimeout(() => {
                this.confirmLoading = false
            }, 2000)
        },
        handleRemoveVideo(data) {
            console.log('删除', data);
            this.ruleForm.video_url = data
        },
        uploadFileSpu(data) {
            this.goodsStyleForm.img_url = data[0]
        },
        handleRemoveSpu(data) {
            this.goodsStyleForm.img_url = ''
        },
    }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/style/editor.css');

.form-item {
    width: 440px;
}

/deep/.disabled .el-upload--picture-card {
    display: none
}

.editor-wrapper {
    width: 800px;
    height: 360px;
    margin-bottom: 60px;
}

.tips {
    color: #666;
    font-size: 14px;
    line-height: 25px;
}
</style>