<template>
  <div>
    <el-table :data="tableData" style="width: 100%" default-expand-all :loading="loading">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div style="color:#07b3db">买家收货地址：{{ props.row.username }}, {{ props.row.phone }}, {{ props.row.province
            + props.row.city + props.row.district + props.row.place }}</div>
        </template>
      </el-table-column>
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column label="退款编号" prop="out_refund_no"></el-table-column>
      <el-table-column label="退款类型" prop="re_type">
        <template slot-scope="scope">
          <div v-if="scope.row.re_type === 1">仅退款</div>
          <div v-if="scope.row.re_type === 2">退货退款</div>
        </template>
      </el-table-column>
      <el-table-column prop="order_sn" label="订单编号"></el-table-column>
      <el-table-column prop="name" label="会员信息" align="center" width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.name">{{ scope.row.name }}</div>
          <div v-if="scope.row.user_phone">{{ scope.row.user_phone }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
      <el-table-column prop="image_url" label="缩略图" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.image_url" style="width:80px;height:80px" />
        </template>
      </el-table-column>
      <el-table-column prop="order_amount" label="实际付款">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <div class="popver-wrapper">
              <div class="title">支付明细</div>
              <div class="con">
                <div class="con-title">商品合计: </div>
                <div class="con-text">¥{{ scope.row.total_amount }}</div>
              </div>
              <div class="con">
                <div class="con-title">消费券: </div>
                <div class="con-text">￥{{ scope.row.refund_coupon }}</div>
              </div>
              <div class="total-con">订单实付: <span>¥{{ scope.row.order_amount }}</span></div>
            </div>
            <div slot="reference" class="name-wrapper">
              ￥{{ scope.row.order_amount || 0.00 }}
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="refund_amount" label="退款金额"></el-table-column>
      <el-table-column prop="create_time" label="退款申请时间" width="160"></el-table-column>
      <el-table-column prop="status" label="退款状态">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top" v-if="scope.row.status === 4">
            <div class="popver-wrapper">
              <div class="title">买家已发货</div>
              <div class="con">
                <div class="con-title">物流公司: </div>
                <div class="con-text">{{ scope.row.wl_corp_name }}</div>
              </div>
              <div class="con">
                <div class="con-title">物流单号: </div>
                <div class="con-text">{{ scope.row.wl_corp_sn }}</div>
              </div>
            </div>
            <div slot="reference" class="name-wrapper" v-if="scope.row.status === 4">买家已发货</div>
          </el-popover>
          <div slot="reference" class="name-wrapper" v-if="scope.row.status != 4">
            <div v-if="scope.row.status === 1">审核中</div>
            <div v-if="scope.row.status === 2">待买家发货</div>
            <div v-if="scope.row.status === 5">已完成</div>
            <div v-if="scope.row.status === 6">已取消</div>
            <div v-if="scope.row.status === 7">审核不通过</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="success" plain size="mini" @click="handleAgreeRefund(scope.$index, scope.row.out_refund_no)"
            v-if="scope.row.status == 1">同意</el-button>
          <el-button type="primary" plain size="mini"
            @click="handleAgreeRefund(scope.$index, scope.row.out_refund_no, '确认收货')"
            v-if="scope.row.status == 4">确认收货并退款</el-button>
          <el-button type="danger" plain size="mini" @click="handleRefuseRefund(scope.$index, scope.row.out_refund_no)"
            v-if="scope.row.status == 1" class="refuse">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
  
<script>
export default {
  props: {
    tableData: Array,
    loading: {
      type: Boolean,
      default: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totalPage: {
      type: Number,
      default: 1
    },
    is_mid: {
      type: Number,
      default: 1
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    showGroupBtn: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    handleAgreeRefund(index, refundNo, type) {
      this.$emit('handleAgreeRefund', index, refundNo, type)
    },
    handleRefuseRefund(index, refundNo, status) {
      this.$emit('handleRefuseRefund', index, refundNo, status)
    }
  }
}
</script>
  
<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #999;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
  color: #000;
}

.name-wrapper {
  color: #f56c6c;
}

.popver-wrapper {
  width: 300px;

  .title {
    font-size: 16px;
    color: #333;
    line-height: 30px;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
    padding-bottom: 10px;
  }

  .con {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
  }

  .con-text {
    color: #f56c6c;
  }

  .total-con {
    border-top: 1px solid #eee;
    margin-top: 10px;
    text-align: right;
    line-height: 40px;
    padding-top: 5px;

    span {
      color: #f56c6c;
    }
  }
}

.cancel-reason {
  font-size: 12px;
  color: #46a6ea;
}

.information-total {
  color: #f56c6c;
  font-weight: bold;
  font-size: 16px;
}

.location-number {
  width: 300px;
}

.shopText {
  line-height: 25px;
  padding: 0 10px;
}
</style>