<template>
    <div>
        <div>
            <el-form :inline="true" :model="formInline" class="search-form">
                <el-form-item label="状态">
                    <el-select v-model="formInline.state" placeholder="请选择">
                        <el-option label="启用" value="1"></el-option>
                        <el-option label="禁用" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="handleSearchClick" icon="el-icon-search">查询</el-button>
                    <el-button size="medium" @click="handleResetClick" icon="el-icon-refresh">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div><el-button type="primary" size="medium" @click="handleToAddPage()" icon="el-icon-plus">新建角色</el-button>
        </div>
        <div style="margin-top:20px">
            <el-table :data="tableData" style="width: 96%" v-loading="loading" border>
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="username" label="用户名" align="center"></el-table-column>
                <el-table-column prop="name" label="员工姓名" align="center"></el-table-column>
                <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                <el-table-column prop="last_login_time" label="上次登录时间" align="center"></el-table-column>
                <el-table-column prop="state" label="状态" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.state">启用</div>
                        <div v-if="!scope.row.state">禁用</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div style="display:flex">
                            <el-button type="primary" plain size="mini" @click="handleToAddPage(scope.row)">编辑</el-button>
                            <el-button type="warning" plain size="mini" @click="handleToResetPassword"
                                v-if="scope.row.username === username">重置密码</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import { getResourceList, resetPassword } from '@/api/api'
import { getToken } from '@/utils/token'
export default {
    data() {
        return {
            formInline: {},
            tableData: [],
            currentPage: 1,
            totalPage: 1,
            page: 1,
            loading: true,
            username: ''
        }
    },
    mounted() {
        this.onList()
        this.username = getToken('username')
    },
    methods: {
        onList() {
            const data = {
                p: this.currentPage,
                state: this.formInline.state
            }
            getResourceList(data).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                }
            })
        },
        handleSearchClick() {
            this.onList()
        },
        handleResetClick() {
            this.currentPage = 1
            this.formInline = {}
            this.onList()
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },
        handleToResetPassword() {
            this.$confirm('重置后密码为123456, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                resetPassword().then(res => {
                    if (res.code === 200) {
                        this.$message.success('密码重置成功,即将跳转至登录页，请重新登录')
                        setTimeout(() => {
                            this.$router.push({
                                name: 'login'
                            })
                        }, 2000)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        handleToAddPage(data) {
            if (data) {
                const row = {
                    name: data.name,
                    id: data.id,
                    username: data.username,
                    state: data.state ? 1 : 0
                }
                this.$router.push({
                    name: 'editJurisdiction',
                    query: {
                        data: row
                    }
                })
            } else {
                this.$router.push({
                    name: 'addJurisdiction',
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.pagination-wrapper {
    margin-top: 10px;
}
</style>