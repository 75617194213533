<template>
  <div class="image-box">
    <el-upload :ref="`Uploader-${uploadId}`" :id="`uploadImage-${uploadId}`" v-loading="loading" action="upload"
      :style="style" :auto-upload="false" list-type="picture-card" :show-file-list="showFileList" :name="uploadId"
      accept="image/*" multiple :limit="maxlength" :file-list="fileList" :before-upload="handleBeforeUpload"
      :http-request="httpRequest" :on-change="handleChange" :on-remove="handleRemove" :on-exceed="handleExceed"
      :before-remove="handleBeforeRemove" :disabled="disabled">
      <i class="el-icon-plus" />
    </el-upload>
    <p class="tips">只能上传 jpg/png 文件，且不超过2Mb</p>
    <p class="tips">{{ tips }}</p>
  </div>
</template>
<script>
import { upLoad } from '@/api/api.js';
export default {
  name: 'AppImagesUploader',
  model: {
    event: 'upload',
    prop: 'images',
  },
  props: {
    name: {
      //上传字段的name
      type: [String],
      required: false,
      default: 'image',
    },
    tips: {
      // 上传提示
      type: [String],
      required: false,
      default: '',
    },
    size: {
      //中间的加号大小
      type: [String],
      required: false,
      default: '28px',
    },
    w: {
      //宽
      type: [String],
      required: false,
      default: 'auto',
    },
    h: {
      //高
      type: [String],
      required: false,
      default: 'auto',
    },
    images: {
      //图片数组
      type: [Array],
      required: false,
      default() {
        return [];
      },
    },
    disabled: {
      type: [Boolean],
      default: false
    },
    maxlength: {
      //图片最大长度
      type: [Number],
      default: 1,
    },

  },
  data() {
    return {
      uploadId: Math.random()
        .toString(36)
        .substr(2)
        .toLocaleUpperCase(),
      loading: false,
      showFileList: true,
      fileList: [],
      fm: new FormData(),
      uploadFiles: [], //待上传的文件列表
      fileTotal: 0, //上传文件总数
    };
  },
  computed: {
    style() {
      const { w, h, size } = this;
      return {
        width: w,
        height: h,
        fontSize: size,
      };
    },
  },
  mounted() {
    console.log(this.images, '12456')
    setTimeout(() => {
      this.fileList = this.images.map((url) => ({ url }));
    }, 100)

  },
  methods: {
    /**   
     * 手动上传回调     
     */
    async httpRequest(file) {
      this.fm.append(this.name, file.file);
      //当fm getall的数组长度与filetotal的长度一致，就说明万事俱备，就差上传了
      if (this.fm.getAll(this.name).length === this.fileTotal) {
        try {
          //这里的就是你的上传方法了
          const { code, images } = await upLoad(this.fm);
          //这里是上传成功之后要做的事情
          if (code === 200) {
            this.showFileList = true;
            if (this.fileList.length) {
              for (const index in images) {
                this.fileList.push({ url: images[index] });
              }
            } else {
              this.fileList = images.map((item) => ({ url: item }));
            }
            this.$emit(
              'uploadMoreSuccess',
              this.fileList.map((item) => item.url)
            );
          }
        } catch (error) {
          this.$message.error(`上传文件出错`);
        } finally {
          //无论成功与失败都要清空文件列表，否则会出现无法上传的情况
          this.uploadFiles = [];
          this.fm.delete(this.name);
          this.loading = false;
        }
      }
    },
    /**
     * 图片上传最大限制
     */
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.maxlength} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    /**
     * 上传前回调
     */
    handleBeforeUpload(file) {
      const isSize = file.size / 1024 / 1024 < 2;
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      this.loading = true;
      if (!isSize) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      if (!isJPG) {
        this.$message.error('上传图片只能是JPG或PNG格式!');
      }
      if (!isSize || !isJPG) {
        this.loading = false;
      }
      return isSize && isJPG;
    },

    /**
     * 文件上传change
     */
    handleChange(file, fileList) {
      //获取添加文件进来的状态
      file.status == 'ready' && this.uploadFiles.push(file.raw);
      this.showFileList = false;
      //获取原始文件的个数
      this.fileTotal = document.getElementsByName(this.uploadId)[0].files.length;
      //如果原始文件和upload的个数相同的时候就说明已经添加完了，可以触发上传的方法了
      console.log("this.fileTotal", this.fileTotal)
      if (this.uploadFiles.length === this.fileTotal) {
        //获取上传文件的组件
        const Uploader = this.$refs[`Uploader-${this.uploadId}`];
        //触发上传文件列表的方法
        this.Uploader = Uploader;
        Uploader.submit();
      }
    },
    handleBeforeRemove(file, fileList) {
      // 显示图片列表UI
      this.showFileList = true;
      // 重置uploadFiles
      this.uploadFiles = [];
    },
    /**
     * 移除图片回调
     */
    handleRemove(file, fileList) {
      // handleBeforeUpload返回fasle,会触发on-remove事件,需要在Before-Remove事件,重置uploadFiles,并显示显示图片列表UI
      console.log("fileList", file, fileList)
      const index = this.fileList.findIndex((item) => item.uid == file.uid);
      console.log("index", index)
      if (index > -1) this.fileList.splice(index, 1);

      this.$emit(
        'uploadRemoveImage',
        this.fileList.map((item) => item.url)
      );
    },
  },
};

</script>  
<style lang="scss" scoped></style>
