<template>
    <div>
        <el-table :data="tableData" style="width: 100%" default-expand-all>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <div style="color:#07b3db">买家收货地址：{{ props.row.username }}, {{ props.row.phone }}, {{
                        props.row.user_addr }}</div>
                </template>
            </el-table-column>
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="order_sn" label="订单编号" align="center"></el-table-column>
            <el-table-column prop="nickname" label="会员信息" align="center" width="120">
                <template slot-scope="scope">
                    <div v-if="scope.row.name">{{ scope.row.name }}</div>
                    <div v-if="scope.row.user_phone">{{ scope.row.user_phone }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
            <el-table-column prop="image_url" label="缩略图" align="center">
                <template slot-scope="scope">
                    <img :src="scope.row.image_url" style="width:80px;height:80px" />
                </template>
            </el-table-column>
            <el-table-column prop="spu_code" label="SPU编码" align="center"> </el-table-column>
            <el-table-column prop="sku_name" label="规格名称" align="center"></el-table-column>
            <el-table-column prop="actual_payment" label="用户实际付款" width="200px" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                        <div class="popver-wrapper">
                            <div class="title">支付明细</div>
                            <div class="con">
                                <div class="con-title">商品合计: </div>
                                <div class="con-text">¥{{ scope.row.total_amount }}</div>
                            </div>
                            <div class="con">
                                <div class="con-title">消费券: </div>
                                <div class="con-text">￥{{ scope.row.coupon_price }}</div>
                            </div>
                            <div class="total-con">订单实付: <span>¥{{ scope.row.actual_payment }}</span></div>
                        </div>
                        <div slot="reference" class="name-wrapper">
                            ￥{{ scope.row.actual_payment || 0.00 }}
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="payment_time" label="下单时间" align="center"> </el-table-column>
            <el-table-column prop="state" label="订单状态" align="center">
                <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top" v-if="scope.row.state === 3 || scope.row.state === 4">
                        <div class="popver-wrapper">
                            <div class="title">发货状态</div>
                            <div class="con">
                                <div class="con-title">物流公司: </div>
                                <div class="con-text">{{ scope.row.wl_corp_name }}</div>
                            </div>
                            <div class="con">
                                <div class="con-title">物流单号: </div>
                                <div class="con-text">{{ scope.row.wl_corp_sn }}</div>
                            </div>
                        </div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state === 3">已发货</div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state === 4">已完成</div>
                    </el-popover>
                    <div slot="reference" class="name-wrapper" v-if="scope.row.state != 3 && scope.row.state != 4">
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state === 1">待支付</div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state === 2">待发货</div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state === 5">已取消</div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state === 6">退款中</div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state === 7">已退款</div>
                        <div slot="reference" class="name-wrapper" v-if="scope.row.state === 8">商品已下架或已删除</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200px" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" plain size="mini" v-if="scope.row.state === 2"
                        @click="handleShowDialog(scope.row)">发货</el-button>
                    <el-button type="primary" plain size="mini" v-if="scope.row.state === 3"
                        @click="handleShowDialogEdit(scope.row)">修改物流</el-button>
                </template>
            </el-table-column>

        </el-table>


    </div>
</template>
  
<script>
export default {
    props: {
        tableData: Array,
        currentPage: {
            type: Number,
            default: 1
        },
        totalPage: {
            type: Number,
            default: 1
        },
    },
    methods: {
        handleShowDialog(data, partGoodsNumber) {
            this.$emit('handleShowDialog', data.order_sn, partGoodsNumber)
        },
        handleShowDialogEdit(data, partGoodsNumber) {
            this.$emit('handleShowDialogEdit', data, partGoodsNumber)
        },
    }
}
</script>
  
<style lang="scss" scoped>
.name-wrapper {
    color: #f56c6c;
}

.popver-wrapper {
    width: 300px;

    .title {
        font-size: 16px;
        color: #333;
        line-height: 30px;
        border-bottom: 1px solid #eee;
        margin-bottom: 5px;
        padding-bottom: 10px;
    }

    .con {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        line-height: 30px;
    }

    .con-text {
        color: #f56c6c;
    }

    .total-con {
        border-top: 1px solid #eee;
        margin-top: 10px;
        text-align: right;
        line-height: 40px;
        padding-top: 5px;

        span {
            color: #f56c6c;
        }
    }
}
</style>