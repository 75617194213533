<template>
    <div class="header">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="滚动内容" prop="title" required>
                <el-input v-model.trim="ruleForm.title" class="form-input" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <sort @onSortInput="handleSortInput" ref="sort" :ruleForm="{ num: this.sort }" style="width:500px" />
            <el-form-item label="是否启用" v-if="isEdit">
                <el-switch v-model="ruleForm.state" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="medium" @click="submitForm('ruleForm')">保存</el-button>
                <goBack />
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import goBack from '@/components/base/goBack.vue'
import sort from '@/components/base/sort.vue'
import { addNotice, editNotice } from '@/api/api'
export default {
    components: {
        goBack, sort
    },
    data() {
        return {
            ruleForm: {},
            isEdit: false,
            rules: {
                title: [{ required: true, message: '请输入滚动内容', trigger: 'blur' }],
            },
            sort: ''
        }
    },
    created() {
        console.log(this.$route.query.data)
        if (this.$route.query.data) {
            this.isEdit = true
            this.ruleForm = this.$route.query.data
            this.sort = this.ruleForm.ordering
        }
    },
    methods: {
        handleSortInput(val) {
            this.sort = val
        },
        submitForm(ruleForm) {
            this.$refs[ruleForm].validate((valid) => {
                if (valid) {
                    const data = {
                        title: this.ruleForm.title,
                        ordering: this.sort,
                    }
                    if (this.isEdit) {
                        data.id = this.ruleForm.id
                        data.state = this.ruleForm.state
                        editNotice(data).then(res => {
                            if (res.code === 200) {
                                this.$router.push({
                                    name: 'horseRaceLampList'
                                })
                            }
                        })
                    } else {
                        addNotice(data).then(res => {
                            if (res.code === 200) {
                                this.$router.push({
                                    name: 'horseRaceLampList'
                                })
                            }
                        })
                    }
                }
            });
        }
    }
}
</script>
<style scoped lang="scss">
.form-input {
    width: 400px;
}
</style>