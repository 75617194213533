<template>
    <div class="wrapper">
        <el-button type="primary" @click="handleAddAvatar">添加头像</el-button>
        <el-button @click="handleDelAvatar">删除头像</el-button>
        <div class="image">
            <div v-for="(item, index) in imgList" :key="item.id" class="img-box">
                <el-checkbox v-model="item.isChecked" @change="handleChange(item.isChecked, item.id)"
                    class="checkbox"></el-checkbox>
                <imageView :src="item.img_url" class="img-url" />
            </div>
        </div>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>
        <el-dialog title="添加头像" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
            <upLoadImage :images="imageList" :maxlength="10" @uploadMoreSuccess="uploadSuccess"
                @uploadRemoveImage="uploadRemoveImage" tips="建议上传尺寸为800 * 800 像素" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import imageView from '@/components/base/image'
import { getAvatar, addAvatar, delAvatar } from '@/api/api'
import upLoadImage from '@/components/upload/uploadMore.vue'
export default {
    components: { imageView, upLoadImage },
    data() {
        return {
            imgList: [],
            dialogVisible: false,
            imageList: [],
            currentPage: 1,
            totalPage: 1,
            checked: [],
            id_list: [],
        }
    },

    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            getAvatar().then(res => {
                if (res.code === 200) {
                    this.imgList = res.data.map(item => {
                        return {
                            isChecked: false,
                            img_url: item.img_url,
                            id: item.id
                        }
                    })
                }
            })
        },
        handleAddAvatar() {
            this.dialogVisible = true
        },
        handleClose() {
            this.dialogVisible = false
            this.imageList = []
        },
        uploadSuccess(data) {
            console.log('uploadImg', data)
            this.imageList = data
        },
        uploadRemoveImage(data) {
            console.log('removeImg', data)
            this.imageList = data
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.onList()
        },
        handleChange(val, id) {
            if (val === true) {
                this.id_list.push(id)
            } else {
                Array.prototype.indexOf = function (val) {
                    for (var i = 0; i < this.length; i++) {
                        if (this[i] == val) return i;
                    }
                    return -1;
                };
                Array.prototype.remove = function (val) {
                    var index = this.indexOf(val);
                    if (index > -1) {
                        this.splice(index, 1);
                    }
                };
                this.id_list.remove(id)
            }
        },
        handleDelAvatar() {
            if (this.id_list.length === 0) {
                this.$message.error("请选择需要删除的头像")
                return false
            } else {
                this.$confirm('确定删除所选头像吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delAvatar({
                        id_list: this.id_list
                    }).then(res => {
                        if (res.code === 200) {
                            this.onList()
                            this.$message.success(res.message)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
        },
        handleSubmit() {
            addAvatar({
                img_url: this.imageList
            }).then(res => {
                if (res.code === 200) {
                    this.onList()
                    this.dialogVisible = false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.wrapper {
    width: 100%;
}

.image {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .img-box {
        position: relative;
    }

    .checkbox {
        position: absolute;
        top: 4px;
        left: 6px;
    }

    .img-url {
        background-color: #07B3DB;
        width: 80px;
        height: 80px;
        border-radius: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        overflow: hidden;
    }
}
</style>