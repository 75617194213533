<template>
    <div class="image-view" @click="handleImageClick">
        <div v-if="src && src !== ''">
            <!-- 加载完成后正确图片 -->
            <el-image :src="src.includes('http') ? src : `https://${src}`" :preview-src-list="srcList">
                <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot no-image">
                    <i class="el-icon-picture-outline"></i>
                    暂无图片
                </div>
            </el-image>
        </div>
        <div v-else>
            <!-- 加载完成后正确图片 -->
            <el-image>
                <div slot="error" class="image-slot no-image">
                    <i class="el-icon-picture-outline"></i>
                    暂无图片
                </div>
            </el-image>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        src: String,
        srcList: Array
    },
    data() {
        return {

        }
    },
    methods: {
        handleImageClick() {
            this.$emit('handleImageClick')
        }
    }
}
</script>

<style lang="scss" scoped>
.image-view {
    width: 100%;
    height: 100%;
}

.no-image {
    color: #999;
    font-size: 14px;
}
</style>