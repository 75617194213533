import axios from "axios"
import { getToken } from "./utils/token.js"
import { Message } from "element-ui"
import router from './router'

const service = axios.create({
    baseURL: 'https://api.bjyangfanqihang.com/api/app/',
    timeout: 50000
})
//添加请求拦截器
service.interceptors.request.use((config) => {
    // 在请求之前 获取并设置token
    config.headers['Authorization'] = getToken('token')
    return config
}, (error) => {
    return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
    // 对响应数据做些什么
    let { code, message } = response.data
    if (code !== 200 && code !== 403) {
        Message({ message: message || 'error', type: 'warning' })
    }
    if (code === 403) {
        Message({ message: '登录状态过期，即将跳转登录页面，请重新登录' || 'error', type: 'warning' })
        setTimeout(() => {
            router.push({
                name: 'login'
            })
        }, 2000);

    }
    return response.data
}, (error) => {
    return Promise.reject(error)
})
export default service