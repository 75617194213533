<template>
    <div class="header">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="昵称" required>
                <el-input v-model.trim="nickname" class="form-input" maxlength="50" show-word-limit></el-input>
                <el-button type="text" size="medium" @click="handleToNickname" style="margin-left:10px">随机获取昵称</el-button>
            </el-form-item>
            <el-form-item label="头像" required>
                <div style="display: flex;">
                    <imageView v-if="avatar" :src="avatar" class="img-url" />
                    <el-button type="text" size="medium" @click="handleToAvatar">随机获取头像</el-button>
                </div>
            </el-form-item>
            <el-form-item label="SPU编码" required prop="spu_code">
                <el-select v-model="ruleForm.spu_code" filterable placeholder="请选择" @focus="handleSpuList">
                    <el-option v-for="item in spuList" :key="item.spu_code" :label="item.spu_code" :value="item.spu_code">
                        <span style="float: left">{{ item.spu_code }}</span>
                        <span class="goods_name">{{ item.goods_name }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="评价内容" prop="content" required class="editor-wrapper">
                <!-- <input id="quill-img" class="quill-img" type="file" style="display:none">
                                <quill-editor v-model="ruleForm.content" ref="myQuillEditor" :options="editorOprion"
                                    class="editor"></quill-editor> -->
                <div>
                    <input id="quill-img" class="quill-img" type="file" style="display:none" />
                    <quill-editor v-model="ruleForm.content" ref="myQuillEditor" :options="editorOprion"
                        class="editor"></quill-editor>
                    <el-upload class="avataruploaderTip" ref="uploadtip" :limit="999" action="#" :show-file-list="false"
                        multiple :http-request="uploadImgTip">
                    </el-upload>
                    <el-dialog title="视频上传" :visible.sync="videoDialog" :close-on-click-modal="false" class="editerV"
                        width="500px">
                        <div class="editerVideo">
                            <div class="editerVideo_title">
                                <div :class="['editerVideo_title_item', videoTab.tabUpd ? 'editerVideo_title_act' : '']">
                                    上传视频</div>
                            </div>
                            <div class="editerVideo_main">
                                <div>
                                    <div v-if="videoProgress" class="videoProgress">视频上传中，请耐心等待！</div>
                                    <el-upload v-else class="avatar-uploader_video" :limit=1 action="#"
                                        :show-file-list="false" :http-request="uploadVideoTip">
                                        <i class="el-icon-plus avatar-uploader-videoiocn"></i>
                                    </el-upload>
                                </div>
                            </div>
                        </div>
                    </el-dialog>
                </div>
            </el-form-item>
            <el-form-item label="显示时间" required>
                <el-date-picker v-model="show_time" type="datetime" placeholder="选择评价日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="medium" @click="submitForm('ruleForm')">保存</el-button>
                <goBack />
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import goBack from '@/components/base/goBack.vue'
import uploadMore from '@/components/upload/uploadMore.vue'
import imageView from '@/components/base/image.vue'
import { addComment, upLoad, getSpu, getRandomName, getRandomAvatar } from '@/api/api'
import { formatTime } from '@/utils/format'

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor, Quill } from 'vue-quill-editor'
import Video from '@/components/editor/video'
// 详情用的options配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
    ['blockquote', 'code-block'], //引用、代码块儿
    [{ header: 1 }, { header: 2 }], //标题，键值对的形式；1、2表示字体大小
    [{ list: 'ordered' }, { list: 'bullet' }], //列表
    [{ script: 'sub' }, { script: 'super' }], //上下标
    [{ indent: '-1' }, { indent: '+1' }], //缩进
    [{ direction: 'rtl' }], //文本方向
    [{ size: ['small', false, 'large', 'huge'] }], //字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
    [{ color: [] }, { background: [] }], //字体规格名称，字体背景规格名称
    [{ font: [] }], //字体
    [{ align: [] }], //对齐方式
    ['clean'], //清除字体样式
    ['image',], //上传图片、上传视频(video)、超链接(link)   
];
const Delta = Quill.import('delta')
Quill.register(Video, true)

export default {
    components: {
        quillEditor, goBack, uploadMore, imageView
    },
    data() {
        return {
            ruleForm: {},
            mainLimit: [],
            indexVideo: 0,
            videoTab: {
                tabUpd: true
            },
            quill_img: '',
            videoProgress: false, // 视频上传进度
            videoDialog: false,  // 视频上传弹窗
            editorOprion: {
                modules: {
                    toolbar: {
                        container: toolbarOptions,
                        handlers: {
                            video: (val) => {
                                this.onVideo()
                            },
                            image: (value) => {
                                // 覆盖默认的图片上传
                                // 获取光标所在的位置
                                var range = this.$refs.myQuillEditor.quill.getSelection();
                                if (range == null) {
                                    this.indexVideo = 0;
                                } else {
                                    this.indexVideo = range.index;
                                }
                                // 点击隐藏的上传表单
                                if (value) {
                                    document.querySelector('.avataruploaderTip input').click()
                                } else {
                                    this.quill.format('image', false)
                                }
                            }
                        }
                    }
                }
            },
            isEdit: false,
            rules: {
                spu_code: [{ required: true, message: '请选择商品' }],
                content: [{ required: true, message: '请输入评价内容' }],
            },
            spuList: [],
            avatar: '',
            nickname: '',
            show_time: '',
        }
    },
    created() {
        if (this.$route.query.data) {
            const _data = JSON.parse(decodeURIComponent(this.$route.query.data))
            this.isEdit = true
            this.ruleForm = _data
        } else {
            const date = new Date()
            this.show_time = formatTime(date, 'yyyy-MM-dd HH:mm:ss')
            console.log(this.show_time)
        }
    },
    computed: {
        // 详情编辑器
        editor() {
            return this.$refs.myQuillEditor.quill;
        }
    },
    methods: {
        onVideo() {
            this.videoDialog = true;
            //当编辑器中没有输入文本时，这里获取到的 range 为 null   获取光标位置
            var range = this.$refs.myQuillEditor.quill.getSelection();
            if (range == null) {
                this.indexVideo = 0;
            } else {
                this.indexVideo = range.index;
            }
        },
        //上传视频
        uploadVideoTip(param) {
            const formData = new FormData()
            formData.append('image', param.file)
            this.videoProgress = true;
            upLoad(formData).then(res => {
                res.images.forEach(item => {
                    const videoURL = `${item}`
                    // 获取富文本
                    let quill = this.$refs.myQuillEditor.quill
                    // 在光标所在位置 插入视频
                    quill.insertEmbed(this.indexVideo, 'video', videoURL)
                    quill.setSelection(this.indexVideo + 1)
                })
                this.videoProgress = false;
                this.videoDialog = false;
            });

        },
        // 图片上传
        uploadImgTip(param) {
            const formData = new FormData()
            formData.append('image', param.file)
            upLoad(formData).then(res => {
                if (res.images && res.images.length > 0) {
                    res.images.forEach(item => {
                        let url = `${item}`
                        let quill = this.$refs.myQuillEditor.quill
                        // 插入图片链接
                        quill.insertEmbed(this.indexVideo, 'image', url)
                        quill.setSelection(this.indexVideo + 1)
                    })
                }
            });
        },
        handleSpuList() {
            getSpu().then(res => {
                if (res.code === 200) {
                    this.spuList = res.data
                }
            })
        },
        handleToNickname() {
            getRandomName().then(res => {
                if (res.code === 200) {
                    this.nickname = res.data[0]
                    console.log(this.ruleForm.nickname)
                }
            })
        },
        handleToAvatar() {
            this.dialogVisible = true
            getRandomAvatar().then(res => {
                if (res.code === 200) {
                    this.avatar = res.data[0]
                }
            })
        },
        submitForm(ruleForm) {
            this.$refs[ruleForm].validate((valid) => {
                if (valid) {
                    if (!this.nickname) {
                        this.$message.error('请输入昵称')
                        return false
                    }
                    if (!this.avatar) {
                        this.$message.error('请上传图片')
                        return false
                    }
                    const date = new Date()
                    console.log(this.show_time)

                    const nowDate = formatTime(date, 'yyyy-MM-dd HH:mm:ss')
                    if (this.show_time > nowDate) {
                        this.$message.error('评价时间不能大于当前时间')
                        return false
                    }
                    const data = {
                        nickname: this.nickname,
                        avatar_url: this.avatar,
                        content: this.ruleForm.content,
                        show_time: this.show_time,
                        spu_code: this.ruleForm.spu_code,
                    }
                    addComment(data).then(res => {
                        if (res.code === 200) {
                            this.$router.push({
                                name: 'commentList'
                            })
                        }
                    })
                }
            })
        },
    }
}
</script>
<style scoped lang="scss">
@import url('@/assets/style/editor.css');

.form-input {
    width: 40%;
}

.editor-wrapper {
    width: 800px;
    height: 360px;
    margin-bottom: 60px;
}

.tips {
    margin-left: 10px;
    color: #c5c5c2;
}

.goods_name {
    float: right;
    color: #8492a6;
    font-size: 13px;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


/deep/.ql-snow .ql-editor img {
    margin-top: -5px;
}

.img-url {
    background-color: #07B3DB;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 20px;
    overflow: hidden;
}
</style>