<template>
    <div>
        <el-form ref="formInline" :model="formInline" label-width="110px" inline>
            <el-form-item label="商品名称">
                <el-input v-model="formInline.goods_name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="SPU编码">
                <el-select v-model="formInline.spu_code" filterable placeholder="请选择" @focus="handleSpuList">
                    <el-option v-for="item in spuList" :key="item.spu_code" :label="item.spu_code" :value="item.spu_code">
                        <span style="float: left">{{ item.spu_code }}</span>
                        <span class="goods_name">{{ item.goods_name }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="上架状态">
                <el-select v-model="formInline.state" placeholder="请选择">
                    <el-option label="上架" :value="1"></el-option>
                    <el-option label="下架" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleSearchClick">查询</el-button>
                <el-button @click="handleResetClick" id="resetSubmit">重置</el-button>
            </el-form-item>
        </el-form>
        <div>
            <el-button type="primary" @click="handleEditShop()">新建商品</el-button>
            <el-button @click="hanleUpGoods" type="primary" plain>批量上架</el-button>
            <el-button @click="handleDownGoods" type="primary" plain>批量下架</el-button>
            <el-button type="danger" plain @click="handleRemoveGoods">批量删除</el-button>
        </div>
        <div style="margin-top:30px;margin-bottom: 20px;">
            <el-table :data="tableData" style="width: 96%" v-loading="loading" @selection-change="handleSelectionChange"
                border>
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
                <el-table-column prop="img_url" label="商品图片" align="center">
                    <template slot-scope="scope">
                        <imageView :src="scope.row.img_url[0]" :srcList="scope.row.image" class="image" />
                    </template>
                </el-table-column>
                <el-table-column prop="spu_code" label="SPU编码" align="center"></el-table-column>
                <el-table-column prop="goods_type" label="商品类型" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.goods_type === 1">普通商品</div>
                        <div v-else>会员商品</div>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="商品状态" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.state">上架</div>
                        <div v-else>下架</div>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="handleEditShop(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import {
    getGoods, delGoods, getSpu, updateGoodsList
} from '@/api/api'
import imageView from '@/components/base/image'
export default {
    components: { imageView },
    data() {
        return {
            loading: true,
            currentPage: 1,
            totalPage: 0,
            formInline: {},
            tableData: [],
            multipleSelection: [],
            spuCodes: [],
            spuList: [],
        }
    },
    created() {

    },
    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            const data = { ...this.formInline }
            data.p = this.currentPage
            getGoods(data).then(res => {
                this.loading = false
                if (res.code === 200) {

                    this.tableData = res.data
                    console.log(typeof res.data)
                    console.log(res.data)
                    this.totalPage = res.tpage
                }
            })
        },
        handleSearchClick() {
            this.currentPage = 1
            this.onList()
        },
        handleResetClick() {
            this.formInline = {}
            this.currentPage = 1
            this.onList()
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },
        handleSpuList() {
            getSpu().then(res => {
                if (res.code === 200) {
                    this.spuList = res.data
                }
            })
        },
        handleEditShop(data) {
            if (data) {
                const _data = encodeURIComponent(JSON.stringify(data))
                this.$router.push({
                    name: 'editProduct',
                    query: { data: _data }
                })
            } else {
                this.$router.push({
                    name: 'addProduct',
                })
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;


        },
        handleRemoveGoods() {
            this.errorMsg('删除', () => {
                this.$confirm('确定要将勾选的商品全部删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const id_list = []
                    this.multipleSelection.forEach(item => {
                        id_list.push(item.spu_code)
                    })
                    delGoods({
                        id_list: id_list
                    }).then(res => {
                        if (res.code === 200) {
                            this.$message.success('删除成功')
                            this.multipleSelection = []
                            this.onList()
                        } else {
                            this.onMultipleSelection()
                        }
                    })
                })
            })
        },
        hanleUpGoods() {
            this.errorMsg('上架商品', () => {
                this.$confirm('确定要将选中商品上架吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.spuCodes = []
                    this.multipleSelection.forEach(item => {
                        this.spuCodes.push(item.spu_code)
                    })
                    updateGoodsList({
                        spu_code: this.spuCodes,
                        state: 1
                    }).then(res => {
                        if (res.code === 200) {
                            this.$message.success('上架成功')
                            this.multipleSelection = []
                            this.onList()
                        }
                    })
                })
            })
        },
        handleDownGoods() {
            this.errorMsg('下架商品', () => {
                this.$confirm('确定要将选中商品下架吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.spuCodes = []
                    this.multipleSelection.forEach(item => {
                        this.spuCodes.push(item.spu_code)
                    })
                    updateGoodsList({
                        spu_code: this.spuCodes,
                        state: 0
                    }).then(res => {
                        if (res.code === 200) {
                            this.$message.success('下架成功')
                            this.multipleSelection = []
                            this.onList()
                        }
                    })
                })
            })
        },
        errorMsg(msg, callback) {
            if (this.multipleSelection.length === 0) {
                this.$message.warning(`没有选择商品,无法批量${msg}哦！`);
            } else {
                callback && callback()
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.image {
    width: 80px;
    height: 80px;
}

.goods_name {
    float: right;
    color: #8492a6;
    font-size: 13px;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
