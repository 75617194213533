import { render, staticRenderFns } from "./deleteBtn.vue?vue&type=template&id=65957957&scoped=true"
import script from "./deleteBtn.vue?vue&type=script&lang=js"
export * from "./deleteBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65957957",
  null
  
)

export default component.exports