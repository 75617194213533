<template>
    <div>
        <div>
            <el-form :model="formLine" :inline="true">
                <el-form-item label="客户名称:">
                    <el-input v-model="formLine.goods_name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="客户手机号码:">
                    <el-input v-model="formLine.phone" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="申请日期:">
                    <el-date-picker v-model="formLine.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="商品名称:">
                    <el-input v-model="formLine.goods_name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="SPU编码:">
                    <el-select placeholder="请选择" v-model="formLine.spu_code" filterable @focus="handleSpuCodeSelect">
                        <el-option v-for="item in spuList" :key="item.id" :label="item.spu_code" :value="item.spu_code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品货号:">
                    <el-input v-model="formLine.sku_code" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="退货编号:">
                    <el-input v-model="formLine.out_refund_no" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="medium" @click="handleSearchClick">查询</el-button>
                    <el-button size="medium" @click="handleReasetClick">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="margin-top: 20px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="`全部(${orderCount.all || 0})`" name="0">
                    <returnList :tableData="tableData" :loading="loading" @handleAgreeRefund="handleAgreeRefund"
                        @handleRefuseRefund="handleRefuseRefund" />
                </el-tab-pane>
                <el-tab-pane :label="`申请退款中(${orderCount.progress || 0})`" name="1">
                    <returnList :tableData="tableData" :loading="loading" @handleAgreeRefund="handleAgreeRefund"
                        @handleRefuseRefund="handleRefuseRefund" />
                </el-tab-pane>
                <el-tab-pane :label="`买家已发货(${orderCount.shipped || 0})`" name="4">
                    <returnList :tableData="tableData" :loading="loading" @handleAgreeRefund="handleAgreeRefund"
                        @handleRefuseRefund="handleRefuseRefund" />
                </el-tab-pane>
                <el-tab-pane :label="`退款成功(${orderCount.success || 0})`" name="5">
                    <returnList :tableData="tableData" />
                </el-tab-pane>
                <el-tab-pane :label="`退款关闭(${orderCount.close || 0})`" name="6">
                    <returnList :tableData="tableData" />
                </el-tab-pane>
            </el-tabs>
        </div>
        <div style="margin-top:20px">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!-- 拒绝原因 -->
        <el-dialog title="拒绝原因" :visible.sync="dialogFormVisible" width="30%" destroy-on-close>
            <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm">
                <el-form-item placeholder="请输入" required prop="reason">
                    <el-input type="textarea" :rows="4" v-model="dialogForm.reason"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleDialogConfirm('dialogForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 退货地址 -->
        <el-dialog title="退货地址" :visible.sync="addressDialogShow" width="40%" destroy-on-close>
            <el-form :model="addressForm" inline ref="addressForm" label-width="100px">
                <el-form-item placeholder="请输入" :rules="addressRules" required prop="address" label="收货人">
                    <el-select v-model="addressForm.id" filterable placeholder="请选择" :loading="addressLoading"
                        @change="handleAddressChange">
                        <el-option v-for="item in addressList" :key="item.id" :label="item.accept_person" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item placeholder="请输入" :rules="addressRules" prop="phone" label="联系方式">
                    <el-input v-model="addressForm.phone" disabled class="dialog-item"></el-input>
                </el-form-item>
                <el-form-item placeholder="请输入" :rules="addressRules" prop="address" label="所在地">
                    <el-input v-model="addressForm.address" disabled class="dialog-item"></el-input>
                </el-form-item>
                <el-form-item placeholder="请输入" :rules="addressRules" prop="place" label="详细地址">
                    <el-input v-model="addressForm.place" disabled class="dialog-item"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addressDialogShow = false">取 消</el-button>
                <el-button type="primary" @click="handleAddressConfirm('addressForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import returnList from '../../components/base/returnList'
import { getReturnList, editRefundOrder, getRefund, getSpu, getCount } from '@/api/api'
import { formatTime } from '../../utils/format'
export default {
    components: { returnList },
    data() {
        return {
            formLine: {},
            tableData: [],
            spuList: [],
            activeName: 0,
            loading: false,
            currentPage: 1,
            totalPage: 1,
            orderCount: {},
            multipleSelection: [],
            orderCompanyLsit: [],
            ghCompamy: [],
            dialogFormVisible: false,
            refundNo: '',
            dialogForm: {},
            dialogRules: {
                reason: [{ required: true, message: '请输入拒绝原因' }]
            },
            addressRules: {
                address: [{ required: true, message: '请选择退货地址' }]
            },
            addressDialogShow: false,
            addressForm: {},
            addressLoading: false,
            addressList: []
        }
    },
    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            let start_time = null
            let end_time = null
            if (this.formLine.date && this.formLine.date[0]) {
                const date1 = new Date(this.formLine.date[0])
                start_time = formatTime(date1, 'yyyy-MM-dd')
            }

            if (this.formLine.date && this.formLine.date[1]) {
                const date2 = new Date(this.formLine.date[1])
                end_time = formatTime(date2, 'yyyy-MM-dd')
            }
            const data = {
                order_sn: this.formLine.order_sn,
                phone: this.formLine.phone,
                start_time: start_time,
                end_time: end_time,
                goods_name: this.formLine.goods_name,
                sku_code: this.formLine.sku_code,
                out_refund_no: this.formLine.out_refund_no,
                p: this.currentPage,
                status: this.activeName,
            }
            if (this.activeName == 0) {
                delete data.status
            }
            getReturnList(data).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                    this.totalPage = res.page
                }
            })
            getCount().then(res => {
                if (res.code === 200) {
                    this.orderCount = res.data
                }
            })

        },
        handleClick(tab) {
            this.activeName = tab.name
            this.currentPage = 1
            this.loading = true
            this.onList()
        },
        handleSpuCodeSelect() {
            if (this.spuList.length <= 0) {
                getSpu().then(res => {
                    if (res.code == 200) {
                        this.spuList = res.data
                    }
                })
            }

        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },
        handleSearchClick() {
            this.loading = true
            this.currentPage = 1
            this.loading = true
            this.onList()
        },
        handleReasetClick() {
            this.formLine = {}
            this.currentPage = 1
            this.loading = true
            this.onList()

        },
        handleRefuseRefund(index, refundNo, status) {
            this.dialogFormVisible = true
            this.refundNo = refundNo
        },
        handleAgreeRefund(index, refundNo, type) {

            this.$confirm('此操作将同意退款给买家不可撤回，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const re = this.tableData[index].re_type
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if (type == '确认收货') {
                    editRefundOrder({
                        out_refund_no: refundNo,
                        status: 5
                    }).then(res => {
                        loading.close();
                        if (res.code === 200) {
                            this.loading = true
                            this.onList()
                            this.$message.success(res.message)
                        }
                    })
                } else {
                    if (re == 1) {
                        // 仅退款
                        editRefundOrder({
                            out_refund_no: refundNo,
                            status: 5
                        }).then(res => {
                            loading.close();
                            if (res.code === 200) {
                                this.loading = true
                                this.onList()
                                this.$message.success(res.message)
                            }
                        })
                    } else {
                        // 2是同意买家退款等待发货
                        // 退货退款
                        loading.close();
                        this.handleAddressSelect()
                        this.addressDialogShow = true
                        this.refundNo = refundNo
                    }
                }

            })
        },
        handleRefuseRefund(index, refundNo, status) {
            this.dialogFormVisible = true
            this.refundNo = refundNo
        },
        handleAddressSelect() {
            if (this.addressList.length <= 0) {
                getRefund().then(res => {
                    this.addressLoading = false
                    if (res.code === 200) {
                        if (res.data && res.data.length > 0) {
                            res.data.forEach(item => {
                                if (item.is_default_area) {
                                    this.addressForm = {
                                        id: item.id,
                                        accept_person: item.accept_person,
                                        phone: item.phone,
                                        address: `${item.province}${item.city}${item.district}`,
                                        place: item.place
                                    }
                                }
                            })
                        }
                        this.addressList = res.data
                    } else {
                        this.addressList = []
                    }
                })
            }
        },
        handleAddressChange(ev) {
            this.addressList.forEach(item => {
                if (item.id === ev) {
                    this.addressForm = {
                        id: item.id,
                        accept_person: item.accept_person,
                        phone: item.phone,
                        address: `${item.province}${item.city}${item.district}`,
                        place: item.place
                    }
                }
            })
        },
        handleDialogConfirm() {
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    // 拒绝
                    editRefundOrder({
                        out_refund_no: this.refundNo,
                        not_pass_reason: this.dialogForm.reason,
                        status: 7
                    }).then(res => {
                        if (res.code === 200) {
                            this.loading = true
                            this.dialogFormVisible = false
                            this.$message.success(res.message)
                            this.onList()
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        handleAddressConfirm() {
            this.$refs['addressForm'].validate((valid) => {
                if (valid) {
                    editRefundOrder({
                        out_refund_no: this.refundNo,
                        return_address: this.addressForm.id,
                        status: 2
                    }).then(res => {
                        if (res.code === 200) {
                            this.addressDialogShow = false
                            this.loading = true
                            this.onList()
                        }
                    })

                } else {
                    return false;
                }
            });
        },

    }

}
</script>
<style lang="scss" scoped></style>