import service from '../service.js'

// 登录
export function login(data) {
    return service({
        method: 'post',
        url: 'rbac/login',
        data
    })
}
// 图片上传
export function upLoad(data) {
    return service({
        method: 'post',
        url: 'goods/files_upload',
        data
    })
}
export function getSpu(params) {
    return service({
        method: 'get',
        url: 'goods/spu_code',
        params
    })
}

// 商品管理
export function getGoods(params) {
    return service({
        method: 'get',
        url: 'goods/spu',
        params
    })
}
export function delGoods(data) {
    return service({
        method: 'delete',
        url: 'goods/spu',
        data
    })
}
export function addGoods(data) {
    return service({
        method: 'post',
        url: 'goods/spu',
        data
    })
}
export function editGoods(data) {
    return service({
        method: 'put',
        url: 'goods/spu',
        data
    })
}
// banner
export function getBanner(params) {
    return service({
        method: 'get',
        url: 'layout/banner',
        params
    })
}
export function delBanner(data) {
    return service({
        method: 'delete',
        url: 'layout/banner',
        data
    })
}
export function addBanner(data) {
    return service({
        method: 'post',
        url: 'layout/banner',
        data
    })
}
export function editBanner(data) {
    return service({
        method: 'put',
        url: 'layout/banner',
        data
    })
}
export function getNotice(params) {
    return service({
        method: 'get',
        url: 'layout/notice',
        params
    })
}
export function delNotice(data) {
    return service({
        method: 'delete',
        url: `layout/notice`,
        data
    })
}
export function addNotice(data) {
    return service({
        method: 'post',
        url: 'layout/notice',
        data
    })
}
export function editNotice(data) {
    return service({
        method: 'put',
        url: 'layout/notice',
        data
    })
}

// 权限管理
export function changePassword(data) {
    return service({
        method: 'put',
        url: 'rbac/change_pwd',
        data
    })
}
export function resetPassword(data) {
    return service({
        method: 'put',
        url: 'rbac/reset_pwd',
        data
    })
}
export function getResourceList(params) {
    return service({
        method: 'get',
        url: 'rbac/user',
        params
    })
}
export function addResource(data) {
    return service({
        method: 'post',
        url: 'rbac/user',
        data
    })
}
export function editResource(data) {
    return service({
        method: 'put',
        url: 'rbac/user',
        data
    })
}
// news
export function getArticle(params) {
    return service({
        method: 'get',
        url: 'article/article',
        params
    })
}
export function delArticle(data) {
    return service({
        method: 'delete',
        url: 'article/article',
        data
    })
}
export function addArticle(data) {
    return service({
        method: 'post',
        url: 'article/article',
        data
    })
}
export function editArticle(data) {
    return service({
        method: 'put',
        url: 'article/article',
        data
    })
}
// 营销中心
export function getSalse(params) {
    return service({
        method: 'get',
        url: 'marketing/base',
        params
    })
}
export function editSalse(data) {
    return service({
        method: 'put',
        url: 'marketing/base',
        data
    })
}
export function getComment(params) {
    return service({
        method: 'get',
        url: 'marketing/comment',
        params
    })
}
export function addComment(data) {
    return service({
        method: 'post',
        url: 'marketing/comment',
        data
    })
}
export function delComment(data) {
    return service({
        method: 'delete',
        url: 'marketing/comment',
        data
    })
}
export function getRandomName(params) {
    return service({
        method: 'get',
        url: 'marketing/random_name',
        params
    })
}
export function getRandomAvatar(params) {
    return service({
        method: 'get',
        url: 'marketing/random_avatar',
        params
    })
}
export function getAvatar(params) {
    return service({
        method: 'get',
        url: 'marketing/avatar',
        params
    })
}
export function addAvatar(data) {
    return service({
        method: 'post',
        url: 'marketing/avatar',
        data
    })
}
export function delAvatar(data) {
    return service({
        method: 'delete',
        url: 'marketing/avatar',
        data
    })
}
// 用户列表
export function getUser(params) {
    return service({
        method: 'get',
        url: 'users/users',
        params
    })
}
export function getComm(params) {
    return service({
        method: 'get',
        url: 'users/comm',
        params
    })
}
export function editComm(data) {
    return service({
        method: 'put',
        url: 'users/comm',
        data
    })
}
// 售后管理
export function getRefund(params) {
    return service({
        method: 'get',
        url: 'refund/sales_return',
        params
    })
}
export function addRefund(data) {
    return service({
        method: 'post',
        url: 'refund/sales_return',
        data
    })
}
export function editRefund(data) {
    return service({
        method: 'put',
        url: 'refund/sales_return',
        data
    })
}
export function delRefund(data) {
    return service({
        method: 'delete',
        url: 'refund/sales_return',
        data
    })
}
export function editDefault(data) {
    return service({
        method: 'put',
        url: 'refund/is_default',
        data
    })
}
// 订单管理
export function getOrder(params) {
    return service({
        method: 'get',
        url: 'orders/order',
        params
    })
}
export function sendOrder(data) {
    return service({
        method: 'post',
        url: 'orders/order',
        data
    })
}
export function editOrder(data) {
    return service({
        method: 'put',
        url: 'orders/order',
        data
    })
}
// 首页
export function getIndex(params) {
    return service({
        method: 'get',
        url: 'statistic/goods_pv',
        params
    })
}
export function updateGoodsList(data) {
    return service({
        method: 'put',
        url: 'goods/spu/state',
        data
    })
}
// 退款
export function getReturnList(params) {
    return service({
        method: 'get',
        url: 'refund/return_order',
        params
    })
}
export function getCount(params) {
    return service({
        method: 'get',
        url: 'refund/refund_quantity',
        params
    })
}
export function editRefundOrder(data) {
    return service({
        method: 'put',
        url: 'refund/return_order',
        data
    })
}
export function getQiniuToken(data) {
    return service({
        method: 'post',
        url: 'goods/upload_token',
        data
    })
}

