import { render, staticRenderFns } from "./goBack.vue?vue&type=template&id=a569d364&scoped=true"
import script from "./goBack.vue?vue&type=script&lang=js"
export * from "./goBack.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a569d364",
  null
  
)

export default component.exports