<template>
  <div class="image-box">
    <el-upload :ref="`Uploader-${uploadId}`" :id="`uploadImage-${uploadId}`" v-loading="loading" action="upload"
      :auto-upload="false" :name="uploadId" accept="accept" multiple :limit="limit" :http-request="httpRequest"
      :on-change="handleChange" :on-remove="handleRemove" :on-exceed="handleExceed" :before-remove="handleBeforeRemove"
      :before-upload="handleBeforeUpload" :disabled="disabled" :file-list="getImageList">
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
    <p class="tips">建议上传 mp4 文件，且不超过50Mb</p>
    <p class="tips">{{ tips }}</p>
  </div>
</template>
<script>
import { upLoad, getQiniuToken } from '@/api/api.js';
import * as qiniu from 'qiniu-js'
export default {
  name: 'AppImagesUploader',
  model: {
    event: 'upload',
    prop: 'images',
  },
  props: {
    name: {
      //上传字段的name
      type: [String],
      required: false,
      default: 'image',
    },
    tips: {
      // 上传提示
      type: [String],
      required: false,
      default: '',
    },
    size: {
      //中间的加号大小
      type: [String],
      required: false,
      default: '28px',
    },
    w: {
      //宽
      type: [String],
      required: false,
      default: 'auto',
    },
    h: {
      //高
      type: [String],
      required: false,
      default: 'auto',
    },
    images: {
      //图片数组
      type: [Array],
      required: false,
      default() {
        return [];
      },
      accept: String
    },
    disabled: {
      type: [Boolean],
      default: false
    },
    limit: {
      type: Number,
      default: 1
    },

  },
  data() {
    return {
      uploadId: Math.random()
        .toString(36)
        .substr(2)
        .toLocaleUpperCase(),
      loading: false,
      showFileList: true,
      fileList: [],
      fm: new FormData(),
      uploadFiles: [], //待上传的文件列表
      fileTotal: 0, //上传文件总数
    };
  },
  computed: {
    getImageList() {
      const list = this.fileList
      console.log("list", list)
      const _list = []
      if (list.length > 0) {
        // 截取图片前缀
        list.forEach(item => {
          _list.push({
            name: item.url,
            url: item.url
          })
        })
        return _list
      } else {
        return []
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.fileList = this.images.map((url) => ({ url }));
    }, 100)

  },
  methods: {
    /**   
     * 手动上传回调     
     */
    async httpRequest(file) {
      this.$emit('uploadFileStart')
      this.fm.append(this.name, file.file);
      console.log('fileTotal', this.name, this.fileTotal)
      console.log('fileTotal1', this.fm.getAll(this.name).length, this.fileTotal)
      console.log('fileTotal1', this.fm.getAll(this.name).length === this.fileTotal)
      //当fm getall的数组长度与filetotal的长度一致，就说明万事俱备，就差上传了
      if (this.fm.getAll(this.name).length === this.fileTotal) {
        try {
          getQiniuToken({
            file_name: file.file.name
          }).then(res => {
            if (res.code == 200) {
              let qiniuToken = res.token
              let keyName = res.key
              const putExtra = {}
              const config = {
                useCdnDomain: true,
                region: qiniu.region.z2
              };
              let that = this
              let imgList = []
              const observable = qiniu.upload(file.file, keyName, qiniuToken, putExtra, config)
              const observer = {
                next(res) { },
                error(err) { },
                complete(res) {
                  const imgUrl = 'https://img.bjyangfanqihang.com/' + res.key
                  const list = {
                    url: imgUrl
                  }
                  that.fileList.push(list);
                  that.$emit(
                    'uploadFile',
                    that.fileList.map((item) => item.url)
                  );
                }
              }
              const subscription = observable.subscribe(observer) // 上传开始
            }
          })
          // //这里的就是你的上传方法了
          // const { code, images } = await upLoad(this.fm);
          // //这里是上传成功之后要做的事情
          // if (code === 200) {
          //   this.showFileList = true;
          //   if (this.fileList.length) {
          //     for (const index in images) {
          //       this.fileList.push({ url: images[index] });
          //     }
          //   } else {
          //     this.fileList = images.map((item) => ({ url: item }));
          //   }
          //   this.$emit(
          //     'uploadFile',
          //     this.fileList.map((item) => item.url)
          //   );
          // }
        } catch (error) {
          console.log(',.,', error)
          this.$message.error(`上传文件出错`);
        } finally {
          //无论成功与失败都要清空文件列表，否则会出现无法上传的情况
          this.uploadFiles = [];
          this.fm.delete(this.name);
          this.loading = false;
        }
      }
    },
    /**
     * 图片上传最大限制
     */
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    /**
     * 上传前回调
     */
    handleBeforeUpload(file) {
      console.log("file", file)
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'audio/mpeg' || file.type === 'image/bmp')
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (isJPG) {
        this.$message.error('只能上传视频文件!');
      }
      if (!isLt2M) {
        this.$message.error('上传视频大小不能超过50MB!');
      }

      return isLt2M && !isJPG;
    },

    /**
     * 文件上传change
     */
    handleChange(file, fileList) {
      //获取添加文件进来的状态
      file.status == 'ready' && this.uploadFiles.push(file.raw);
      this.showFileList = false;
      //获取原始文件的个数
      this.fileTotal = document.getElementsByName(this.uploadId)[0].files.length;
      //如果原始文件和upload的个数相同的时候就说明已经添加完了，可以触发上传的方法了
      if (this.uploadFiles.length === this.fileTotal) {
        //获取上传文件的组件
        const Uploader = this.$refs[`Uploader-${this.uploadId}`];
        //触发上传文件列表的方法
        this.Uploader = Uploader;
        Uploader.submit();
      }
    },
    handleBeforeRemove(file, fileList) {
      // 显示图片列表UI
      this.showFileList = true;
      // 重置uploadFiles
      this.uploadFiles = [];
      fileList.forEach((item, index) => {
        if (item.name === file.name) {
          this.handleRemove(file, fileList, index)
        }
      })
    },
    /**
     * 移除图片回调
     */
    handleRemove(file, fileList, index) {
      // const index = this.fileList.findIndex((item) => item.uid == file.uid);
      if (index > -1) this.fileList.splice(index, 1);
      this.$emit(
        'handleRemove',
        this.fileList.map((item) => item.url)
      );
    },

  },
};

</script>  
<style lang="scss" scoped>
.image-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #fff;

    img {
      display: inline-block;
      width: 100%;
    }
  }
}
</style>
