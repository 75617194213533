import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/style/reset.css'
import 'font-awesome/css/font-awesome.min.css'
// import axios from 'axios'
import service from './service'

Vue.prototype.service = service // 挂载到原型在全局使用
// Vue.prototype.axios = axios // 挂载到原型在全局使用
Vue.use(ElementUI)
Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  const type = to.meta.login
  // 判断该路由是否需要登录权限
  if (type) {
    if (localStorage.getItem('isLogin')) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()  // 确保一定要有next()被调用
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
