<template>
    <div>
        <div style="margin-bottom:20px">
            <el-button type="primary" @click="handleAddBanner()">新建banner</el-button>
        </div>
        <el-table :data="tableData" style="width: 96%" v-loading="loading" border>
            <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
            <el-table-column prop="title" label="banner名称" align="center"></el-table-column>
            <el-table-column prop="img" label="banner图片" align="center">
                <template slot-scope="scope">
                    <imageView v-if="scope.row.img" :src="scope.row.img" class="image" />
                    <div v-else>视频类型不支持预览</div>
                </template>
            </el-table-column>
            <el-table-column prop="ordering" label="排序" align="center"></el-table-column>
            <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="spu_code" label="商品SPU" align="center">
                <template slot-scope="scope">
                    <p v-if="scope.row.spu_code">{{ scope.row.spu_code }}</p>
                    <p v-else>/</p>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="跳转类型" align="center">
                <template slot-scope="scope">
                    <p v-if="scope.row.type == 1">商品</p>
                    <p v-else>不跳转</p>
                </template>
            </el-table-column>
            <el-table-column prop="state" label="是否启用" align="center">
                <template slot-scope="scope">
                    <p v-if="scope.row.state == 1">启用</p>
                    <p v-else>关闭</p>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" plain size="mini" @click="handleAddBanner(scope.row)">编辑</el-button>
                    <deleteBtn @handleDeleteClick="handleDeleteClick" :id="scope.row.id" :index="scope.$index" />
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-count="totalPage"
                layout="prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { getBanner, delBanner } from '@/api/api.js'
import deleteBtn from '@/components/base/deleteBtn'
import imageView from '@/components/base/image'
export default {
    components: { deleteBtn, imageView },
    data() {
        return {
            loading: true,
            currentPage: 1,
            totalPage: 1,
            formInline: {},
            tableData: [],
        }
    },

    mounted() {
        this.onList()
    },
    methods: {
        onList() {
            const data = { ...this.formInline }
            data.p = this.currentPage
            getBanner(data).then(res => {
                this.loading = false
                if (res.code === 200) {
                    this.tableData = res.data
                    this.totalPage = res.tpage
                }
            })
        },
        handleCurrentChange(page) {
            this.currentPage = page
            this.loading = true
            this.onList()
        },

        handleAddBanner(data) {
            if (data) {
                const row = {
                    id: data.id,
                    title: data.title,
                    ordering: data.ordering,
                    img: data.img,
                    type: data.type,
                    spu_code: data.spu_code ? data.spu_code : '',
                    is_img: data.is_img,
                    state: data.state ? 1 : 0
                }
                const _data = encodeURIComponent(JSON.stringify(row))

                this.$router.push({
                    name: 'editBanner',
                    query: { data: _data }
                })
            } else {
                this.$router.push({
                    name: 'addBanner',
                })
            }
        },
        handleDeleteClick(id, index) {
            const id_list = []
            id_list.push(id)
            delBanner({
                id_list: id_list
            }).then(res => {
                if (res.code === 200) {
                    this.$message.success('删除成功！')
                    this.onList()
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.image {
    width: 80px;
    height: 80px;
}
</style>
